import axiosInstance from "../../api/axiosInstance";
import { fetchProductRequest, fetchProductSuccess, fetchProductFailure } from "./productSlice";

// Action to fetch products
export const fetchProducts = () => async (dispatch) => {
  dispatch(fetchProductRequest());
  try {
    const response = await axiosInstance.get("/products");
    dispatch(fetchProductSuccess(response.data));
    return response.data; // Return response data
  } catch (error) {
    dispatch(fetchProductFailure(error.message));
  }
};

// Action to add a new product
export const addProduct = (formData) => async (dispatch, getState) => {
  dispatch(fetchProductRequest());
  try {
    const response = await axiosInstance.post("/products", formData);
    if (response) {
      const product = response.data.product;
      const { products } = getState().products;
      dispatch(fetchProductSuccess([product, ...products]));
    }
    return response.data;
  } catch (error) {
    if (error && error.response) {
      dispatch(fetchProductFailure(error.response.data.message));
    } else {
      dispatch(fetchProductFailure(error.message));
    }
    return error;
  }
};

// Action to update product
export const updateProduct = (id, formData) => async (dispatch, getState) => {
  dispatch(fetchProductRequest());
  try {
    const response = await axiosInstance.put(`/products/${id}`, formData);
    const updatedProduct = response.data.product;
    const { products } = getState().products;

    const updatedProducts = products.map((product) => (product.id === updatedProduct.id ? updatedProduct : product));

    dispatch(fetchProductSuccess(updatedProducts));

    return response.data;
  } catch (error) {
    dispatch(fetchProductFailure(error.message));
    return error;
  }
};

// Action to delete a product
export const deleteProduct = (productId) => async (dispatch, getState) => {
  dispatch(fetchProductRequest());
  try {
    const response = await axiosInstance.delete("/products/" + productId);
    const { products } = getState().products;
    const updatedProducts = products.filter((product) => product.id !== productId);
    dispatch(fetchProductSuccess(updatedProducts));
    return response.data;
  } catch (error) {
    if (error.response) {
      dispatch(fetchProductFailure(error.response.data.message));
    } else {
      dispatch(fetchProductFailure(error.message));
    }
  }
};
