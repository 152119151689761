import axiosInstance from "../../api/axiosInstance";
import { fetchWallpaperFailure, fetchWallpaperRequest, fetchWallpaperSuccess } from "./staticWallpaperSlice";

// Action to fetch categories
export const fetchWallpapers = (catName) => async (dispatch) => {
  dispatch(fetchWallpaperRequest());
  try {
    const response = await axiosInstance.get("/static-wallpaper/category/" + catName);
    dispatch(fetchWallpaperSuccess(response.data));
    return response.data; // Return response data
  } catch (error) {
    dispatch(fetchWallpaperFailure(error.message));
  }
};

// Action to add a new wallpaper
export const addWallpaper = (wallpaper) => async (dispatch, getState) => {
  dispatch(fetchWallpaperRequest());
  try {
    const response = await axiosInstance.post("/static-wallpaper/upload/multiple", wallpaper);
    const newWallpaper = response.data[0];
    const { wallpapers } = getState().staticWallpaper;
    dispatch(fetchWallpaperSuccess([newWallpaper, ...wallpapers]));
    return response;
  } catch (error) {
    dispatch(fetchWallpaperFailure(error.message));
    return error;
  }
};

// Action to add a new wallpaper artistic
export const addWallpaperArtistic = (wallpaper) => async (dispatch, getState) => {
  dispatch(fetchWallpaperRequest());
  try {
    const response = await axiosInstance.post("/static-wallpaper/upload", wallpaper);
    const newWallpaper = response.data;
    const { wallpapers } = getState().staticWallpaper;
    dispatch(fetchWallpaperSuccess([newWallpaper, ...wallpapers]));
    return response;
  } catch (error) {
    dispatch(fetchWallpaperFailure(error.message));
    return error;
  }
};

// Action to update wallpaper
export const updateWallpaper = (id, formData) => async (dispatch, getState) => {
  dispatch(fetchWallpaperRequest());
  try {
    const response = await axiosInstance.put(`/static-wallpaper/${id}`, formData);
    const newWallpaper = response.data;
    const { wallpapers } = getState().staticWallpaper;

    const updatedWallpaper = wallpapers.map((wallpaper) => (wallpaper.id === newWallpaper.id ? newWallpaper : wallpaper));

    dispatch(fetchWallpaperSuccess(updatedWallpaper));

    return { message: "Wallpaper updated successfully!" };
  } catch (error) {
    dispatch(fetchWallpaperFailure(error.message));
  }
};
// Action to update wallpaper
export const updateWallpaperArtistic = (id, formData) => async (dispatch, getState) => {
  dispatch(fetchWallpaperRequest());
  try {
    const response = await axiosInstance.put(`/static-wallpaper/upload/${id}`, formData);
    const newWallpaper = response.data;
    const { wallpapers } = getState().staticWallpaper;

    const updatedWallpaper = wallpapers.map((wallpaper) => (wallpaper.id === newWallpaper.id ? newWallpaper : wallpaper));

    dispatch(fetchWallpaperSuccess(updatedWallpaper));

    return { message: "Wallpaper updated successfully!" };
  } catch (error) {
    dispatch(fetchWallpaperFailure(error.message));
  }
};

// Action to delete a category
// export const deleteCategory = (categoryId) => async (dispatch, getState) => {
//   dispatch(fetchCategoriesRequest());
//   try {
//     await axiosInstance.delete("/static-categories/" + categoryId);
//     const { categories } = getState().staticCategory; // Get the current state of categories
//     const updatedCategories = categories.filter((category) => category.id !== categoryId); // Filter out the deleted category
//     dispatch(fetchCategoriesSuccess(updatedCategories)); // Update the state with the new list
//     return { message: "Category deleted successfully!" };
//   } catch (error) {
//     dispatch(fetchCategoriesFailure(error.message));
//   }
// };
