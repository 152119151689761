import {
    Box,
    Flex,
    Heading,
    Input,
    Button,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Spinner,
    useDisclosure,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter,
    FormControl,
    FormLabel,
    Image,
    useToast,
  } from "@chakra-ui/react";
  import { useState, useEffect } from "react";
  import { useDispatch, useSelector } from "react-redux";
  import { selectCategories, selectError, selectLoading } from "../redux/liveCategory/liveCategorySelectors";
  import { addCategory, deleteCategory, fetchCategories, updateCategory } from "../redux/liveCategory/liveCategoryActions";
  import DeleteModal from "../components/DeleteModal";
  import ViewImageModal from "../components/ViewImageModal";
  import { convertSize } from "../utils";
  
  function LiveCategory() {
    const [searchTerm, setSearchTerm] = useState("");
    const addCategoryModal = useDisclosure();
    const viewImageModal = useDisclosure();
    const deleteCategoryModal = useDisclosure(); // New modal for delete confirmation
    const [newCategoryName, setNewCategoryName] = useState("");
    const [newCategoryImage, setNewCategoryImage] = useState(null);
    const [imageFile, setImageFile] = useState(null);
    const [viewImage, setViewImage] = useState(null);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [categoryToDelete, setCategoryToDelete] = useState(null); // To hold the ID of the category to delete
  
    const dispatch = useDispatch();
    const categories = useSelector(selectCategories);
    const loading = useSelector(selectLoading);
    const error = useSelector(selectError);
    const toast = useToast();
  
    useEffect(() => {
      dispatch(fetchCategories());
    }, [dispatch]);
  
    useEffect(() => {
      if (error) {
        toast({
          title: "Error fetching categories.",
          description: error,
          status: "error",
          duration: 3000,
          isClosable: true,
          position: "top-right",
        });
      }
    }, [toast, error]);
  
    const handleImageChange = (e) => {
      const file = e.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setNewCategoryImage(reader.result);
          setImageFile(file);
        };
        reader.readAsDataURL(file);
      }
    };
  
    const handleAddCategory = async () => {
      const formData = new FormData();
      formData.append("catName", newCategoryName);
      formData.append("liveCategoryImage", imageFile);
  
  
      try {
        const response = selectedCategory ? await dispatch(updateCategory(selectedCategory?.id, formData)) : await dispatch(addCategory(formData));
        toast({
          title: "Category",
          description: response.message,
          status: "success",
          duration: 3000,
          isClosable: true,
          position: "top-right",
        });
      } catch (error) {
        toast({
          title: "Error adding category.",
          description: error,
          status: "error",
          duration: 3000,
          isClosable: true,
          position: "top-right",
        });
      } finally {
        setNewCategoryName("");
        setNewCategoryImage(null);
        addCategoryModal.onClose();
      }
    };
  
    const handleImageClick = (image) => {
      setViewImage(image);
      viewImageModal.onOpen();
    };
  
    const handleEditCategory = (category) => {
      setSelectedCategory(category);
      setNewCategoryName(category.catName);
      setNewCategoryImage(process.env.REACT_APP_MEDIA_URL + "/" + category.imageName);
      addCategoryModal.onOpen();
    };
  
    const openDeleteModal = (id) => {
      setCategoryToDelete(id);
      deleteCategoryModal.onOpen();
    };
  
    const handleDeleteCategory = async () => {
      try {
        const response = await dispatch(deleteCategory(categoryToDelete));
        toast({
          title: response.message, // Show success message from response
          status: "success",
          duration: 3000,
          isClosable: true,
          position: "top-right",
        });
      } catch (error) {
        toast({
          title: "Error deleting category.",
          description: error,
          status: "error",
          duration: 3000,
          isClosable: true,
          position: "top-right",
        });
      } finally {
        deleteCategoryModal.onClose();
      }
    };
  
    const filteredCategories = categories?.filter((category) => category.catName.toLowerCase().includes(searchTerm.toLowerCase()));
  
    return (
      <Box p={5} bg="gray.100" minHeight="calc(100vh - 40px)">
        <Flex justify="space-between" align="center" mb={6}>
          <Heading as="h2" size="xl" color="teal.600">
            Live Categories
          </Heading>
          <Button colorScheme="teal" variant="solid" _hover={{ bg: "teal.700" }} onClick={addCategoryModal.onOpen}>
            Add Category
          </Button>
        </Flex>
        <Input
          placeholder="Search categories..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          mb={4}
          borderColor="teal.300"
          _focus={{ borderColor: "teal.500", boxShadow: "outline" }}
        />
        {loading ? (
          <Flex justify={"center"} mt={4}>
            <Spinner size="lg" color="teal.500" />
          </Flex>
        ) : error ? (
          <Box color="red.500">{error}</Box>
        ) : categories.length === 0 ? (
          <Box>No categories found</Box>
        ) : (
          <Box overflowY="auto" maxH="calc(100vh - 200px)" borderWidth="1px" borderRadius="md" bg="white">
            <Table variant="simple" size="sm">
              <Thead>
                <Tr>
                  <Th>ID</Th>
                  <Th>Image</Th>
                  <Th>Name</Th>
                  <Th>Size</Th>
                  <Th>Dimension</Th>
                  <Th>Created At</Th>
                  <Th>Actions</Th>
                </Tr>
              </Thead>
              <Tbody>
                {filteredCategories?.map((category) => (
                  <Tr key={category.id}>
                    <Td>{category.id}</Td>
                    <Td>
                      <Box
                        boxSize="50px"
                        borderRadius="md"
                        overflow="hidden"
                        cursor="pointer"
                        onClick={() => handleImageClick(process.env.REACT_APP_MEDIA_URL + "/" + category.imageName)}
                      >
                        <img
                          src={process.env.REACT_APP_MEDIA_URL + "/" + category.imageName}
                          alt={category.catName}
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                          }}
                        />
                      </Box>
                    </Td>
                    <Td>{category.catName}</Td>
                    <Td>{category.size ? convertSize(category.size) : '-'}</Td>
                    <Td>{category.dimension ?? '-'}</Td>
                    <Td>{new Date(category.createdAt).toLocaleDateString()}</Td>
                    <Td>
                      <Button size="sm" colorScheme="blue" onClick={() => handleEditCategory(category)}>
                        Edit
                      </Button>
                      <Button size="sm" colorScheme="red" onClick={() => openDeleteModal(category.id)} ml={2}>
                        Delete
                      </Button>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Box>
        )}
  
        {/* Modal for Adding Category */}
        <Modal
          isOpen={addCategoryModal.isOpen}
          onClose={() => {
            setNewCategoryName("");
            setNewCategoryImage(null);
            addCategoryModal.onClose();
          }}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Add New Category</ModalHeader>
            <ModalBody>
              <FormControl>
                <FormLabel>Category Name</FormLabel>
                <Input value={newCategoryName} onChange={(e) => setNewCategoryName(e.target.value)} placeholder="Enter category name" />
              </FormControl>
              <FormControl mt={4}>
                <FormLabel>Upload Image</FormLabel>
                <Input type="file" style={{ paddingTop: "5px" }} accept="image/*" onChange={handleImageChange} />
                {newCategoryImage && <Image src={newCategoryImage} alt="Preview" mt={2} boxSize="100px" objectFit="cover" />}
              </FormControl>
            </ModalBody>
            <ModalFooter>
              <Button colorScheme="teal" onClick={handleAddCategory} isDisabled={!newCategoryName || !newCategoryImage} isLoading={loading}>
                {selectedCategory ? "Update" : "Add"} {/* Change button text */}{" "}
              </Button>
              <Button
                onClick={() => {
                  setNewCategoryName("");
                  setNewCategoryImage(null);
                  addCategoryModal.onClose();
                }}
                ml={3}
              >
                Cancel
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
  
        {/* Modal for Viewing Image */}
        <ViewImageModal modal={viewImageModal} setViewImage={setViewImage} viewImage={viewImage} />
  
        {/* Modal for Delete Confirmation */}
        <DeleteModal modal={deleteCategoryModal} onDelete={handleDeleteCategory} loading={loading} />
      </Box>
    );
  }
  
  export default LiveCategory;