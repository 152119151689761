import { createSlice } from '@reduxjs/toolkit';

const eliteCategorySlice = createSlice({
  name: 'eliteCategory',
  initialState: {
    categories: [],
    loading: false,
    error: null,
  },
  reducers: {
    fetchCategoriesRequest(state) {
      state.loading = true;
    },
    fetchCategoriesSuccess(state, action) {
      state.loading = false;
      state.categories = action.payload;
    },
    fetchCategoriesFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  fetchCategoriesRequest,
  fetchCategoriesSuccess,
  fetchCategoriesFailure,
} = eliteCategorySlice.actions;

export default eliteCategorySlice.reducer;
