import {
  Box,
  Flex,
  Text,
  IconButton,
  Badge,
  Spinner,
  Center,
  useToast,
  Icon,
  Tooltip,
  Button,
  useDisclosure,
  SimpleGrid,
  Select,
  Switch, // Import Switch component for ascending/descending toggle
} from "@chakra-ui/react";
import { EditIcon, DeleteIcon } from "@chakra-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import { selectWallpapers } from "../redux/staticWallpaper/staticWallpaperSelector";
import { selectError, selectLoading } from "../redux/staticCategory/staticCategorySelectors";
import { fetchWallpapers } from "../redux/staticWallpaper/staticWallpaperActions";
import { useEffect, useState, useRef } from "react";
import { formatFileSize } from "../utils";
import { FaDownload, FaEye, FaHeart } from "react-icons/fa";

import axiosInstance from "../api/axiosInstance";
import AlertDialogComp from "../components/AlertDialogComp";
import { FixedSizeList as List } from "react-window";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import ArtisticImageUploadModal from "../components/ArtisticImageUploadModal";

const WallpaperItem = ({ wallpaper, onEdit, onDelete }) => {
  const [likes, setLikes] = useState(wallpaper.likes);
  const [isLiking, setIsLiking] = useState(false);

  const handleLikeWallpaper = async () => {
    setIsLiking(true);
    try {
      // Optimistically increment the likes count
      setLikes((prevLikes) => prevLikes + 1);

      // Send the like request to the backend
      // await axiosInstance.post(`/like-wallpaper/${wallpaper.id}`);
    } catch (error) {
      console.log(error);
      // If there was an error, revert the likes count
      setLikes((prevLikes) => prevLikes - 1);
    } finally {
      setIsLiking(false);
    }
  };

  return (
    <Box borderWidth="1px" borderRadius="lg" overflow="hidden" boxShadow="md" position="relative">
      <Box position="relative">
        <LazyLoadImage
          src={process.env.REACT_APP_MEDIA_URL + wallpaper.imageName}
          alt={wallpaper.imageName}
          effect="blur"
          width="100%"
          style={{ maxHeight: "200px", height: "100%", objectFit: "cover", minHeight: "200px" }}
        />
        <Box position="absolute" bottom={2} left={2} bg="rgba(0, 0, 0, 0.6)" color="white" px={2} py={1} borderRadius="md" fontSize="sm">
          {wallpaper.dimension}
        </Box>
        <Box position="absolute" bottom={2} right={2} bg="rgba(0, 0, 0, 0.6)" color="white" px={2} py={1} borderRadius="md" fontSize="sm">
          {wallpaper.size && formatFileSize(wallpaper.size)}
        </Box>
      </Box>

      <Flex position="absolute" top={2} right={2}>
        <IconButton aria-label="Edit Wallpaper" icon={<EditIcon />} colorScheme="teal" size="sm" mr={2} onClick={() => onEdit(wallpaper)} />
        <IconButton aria-label="Delete Wallpaper" icon={<DeleteIcon />} colorScheme="red" size="sm" onClick={() => onDelete(wallpaper.id)} />
      </Flex>

      <Box p={4}>
        <Flex justifyContent="space-between" alignItems="center" mb={2}>
          <Badge colorScheme="teal">{wallpaper?.catName}</Badge>
          <Badge
            colorScheme={getSubscriptionBadgeColor(wallpaper?.subscriptionType)}
          >
            {wallpaper?.subscriptionType}
          </Badge>
          <Text fontSize="sm" color="gray.500">
            {new Date(wallpaper.createdAt).toLocaleDateString()}
          </Text>
        </Flex>

        <Text fontWeight="bold" fontSize="md" mb={2}>
          <Tooltip label={wallpaper.originalName} fontSize="md">
            <span>{wallpaper.originalName}</span>
          </Tooltip>
        </Text>

        <Flex justifyContent="space-between" alignItems="center">
          {/* Like Button */}
          <Flex alignItems="center" mr={4}>
            <IconButton aria-label="Like Wallpaper" icon={<FaHeart />} colorScheme="red" size="sm" isLoading={isLiking} onClick={handleLikeWallpaper} />
            <Text fontSize="sm" ml={2}>
              {likes}
            </Text>
          </Flex>

          <Flex alignItems="center" mr={4}>
            <Icon as={FaEye} color="gray.500" mr={1} />
            <Text fontSize="sm">{wallpaper.views}</Text>
          </Flex>

          <Flex alignItems="center">
            <Icon as={FaDownload} color="blue.500" mr={1} />
            <Text fontSize="sm">{wallpaper.downloads}</Text>
          </Flex>
        </Flex>
      </Box>
    </Box>
  );
};

const getSubscriptionBadgeColor = (type) => {
  switch (type) {
    case "Free":
      return "blue";
    case "Pro":
      return "purple";
    case "Ads":
      return "orange";
    case "Seasonal":
      return "yellow";
    case "Time":
      return "teal";
    case "Flash":
      return "pink";
    case "Once":
      return "cyan";
    default:
      return "gray"; // Default color if type is unknown
  }
};

const ArtisticWallpaper = () => {
  const catName = "Artistic";
  const dispatch = useDispatch();
  const wallpapers = useSelector(selectWallpapers);
  const loading = useSelector(selectLoading);
  const error = useSelector(selectError);
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [editData, seteditData] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [sortOrder, setSortOrder] = useState(""); // Empty for default "Show All"
  const [isAscending, setIsAscending] = useState(true); // Add state for sorting order (asc/desc)

  const { isOpen: isDeleteOpen, onOpen: onDeleteOpen, onClose: onDeleteClose } = useDisclosure();
  const cancelRef = useRef();
  const [deleteData, setDeleteData] = useState(null);

  const ITEM_HEIGHT = 340; // Set item height
  const COLUMN_COUNT = 5; // Number of columns
  const ROW_HEIGHT = ITEM_HEIGHT; // Total height of each row
  const visibleItemsCount = Math.ceil(wallpapers.length / COLUMN_COUNT);

  useEffect(() => {
    dispatch(fetchWallpapers(catName));
  }, [dispatch, catName]);

  useEffect(() => {
    if (error) {
      toast({
        title: "Error fetching categories.",
        description: error,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }
  }, [toast, error]);

  const handleEditWallpaper = (id) => {
    seteditData(id);
    onOpen();
  };

  const handleDeleteWallpaper = (id) => {
    setDeleteData(id);
    onDeleteOpen();
  };

  const confirmDeleteWallpaper = async () => {
    console.log("clicks");

    setIsDeleting(true);
    try {
      await axiosInstance.delete(`/static-wallpaper/${deleteData}`);
      toast({
        title: "Wallpaper deleted.",
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      dispatch(fetchWallpapers(catName));
      onDeleteClose();
    } catch (error) {
      console.log(error);
      toast({
        title: "Error deleting wallpaper.",
        description: error.response?.data?.message || "An error occurred.",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    } finally {
      setIsDeleting(false);
    }
  };

  const sortedWallpapers = [...wallpapers].sort((a, b) => {
    if (!sortOrder) return 0; // If "Show All" is selected, no sorting
    if (isAscending) {
      return a[sortOrder] - b[sortOrder];
    } else {
      return b[sortOrder] - a[sortOrder];
    }
  });

  if (loading) {
    return (
      <Center height="100vh">
        <Spinner size="xl" color="teal.500" />
      </Center>
    );
  }

  return (
    <Box p={5}>
      <Flex justify={"space-between"} alignItems={"center"} mb={2}>
        <Text fontSize="xl" fontWeight="bold">
          {catName} {"(" + wallpapers?.length + ")"}
        </Text>
        <Flex gap={4} alignItems={"center"} mb={2}>
          <Button variant={"solid"} colorScheme="teal" _hover={{ bg: "teal.700" }} onClick={onOpen}>
            Add Wallpaper
          </Button>
          <Box>
            <Flex>
              <Select
                value={sortOrder}
                onChange={(e) => setSortOrder(e.target.value)} // Update the sorting option
                width="200px"
                colorScheme="teal"
                mr={4}
              >
                <option value="">Show All</option> {/* Default option to show all data without sorting */}
                <option value="views">Sort by Views</option>
                <option value="downloads">Sort by Downloads</option>
                <option value="likes">Sort by Likes</option>
              </Select>
              <Flex alignItems="center">
                <Text mr={2}>Asc</Text>
                <Switch isChecked={!isAscending} onChange={() => setIsAscending(!isAscending)} />
                <Text ml={2}>Desc</Text>
              </Flex>
            </Flex>
          </Box>
        </Flex>
      </Flex>

      <Box>
        {sortedWallpapers.length === 0 ? (
          <Center height="50vh">
            <Text fontSize="xl" color="gray.500">
              No wallpapers available.
            </Text>
          </Center>
        ) : (
          <List height={window.innerHeight - 100} itemCount={visibleItemsCount} itemSize={ROW_HEIGHT} width="100%">
            {({ index, style }) => {
              const startIndex = index * COLUMN_COUNT;
              const endIndex = Math.min(startIndex + COLUMN_COUNT, sortedWallpapers.length);
              return (
                <div style={style}>
                  <SimpleGrid columns={{ base: 1, sm: 2, md: 3, lg: 4, xl: 5 }} gap={5}>
                    {sortedWallpapers.slice(startIndex, endIndex).map((wallpaper) => (
                      <WallpaperItem key={wallpaper.id} wallpaper={wallpaper} onEdit={handleEditWallpaper} onDelete={handleDeleteWallpaper} />
                    ))}
                  </SimpleGrid>
                </div>
              );
            }}
          </List>
        )}
      </Box>

      {/* Modal for edit wallpaper */}
      <ArtisticImageUploadModal isOpen={isOpen} onClose={onClose} editData={editData} seteditData={seteditData} catName={catName} />

      {/* Alert Dialog for delete confirmation */}
      <AlertDialogComp
        isOpen={isDeleteOpen}
        onClose={onDeleteClose}
        cancelRef={cancelRef}
        title="Delete Wallpaper"
        body="Are you sure you want to delete this wallpaper?"
        isLoading={isDeleting}
        confirm={confirmDeleteWallpaper}
      />
    </Box>
  );
};

export default ArtisticWallpaper;
