import { configureStore } from "@reduxjs/toolkit";
import staticCategoryReducer from "./staticCategory/staticCategorySlice";
import staticWallpaperReducer from "./staticWallpaper/staticWallpaperSlice";
import liveCategoryReducer from "./liveCategory/liveCategorySlice";
import liveWallpaperReducer from "./liveWallpaper/liveWallpaperSlice";
import eliteCategoryReducer from "./eliteCategory/eliteCategorySlice";
import eliteWallpaperReducer from "./eliteWallpaper/eliteWallpaperSlice";
import ProductReducer from "./product/productSlice";
import TrendReducer from "./trend/trendSlice";
import DashboardReducer from "./dashboard/dashboardSlice";

const store = configureStore({
  reducer: {
    staticCategory: staticCategoryReducer,
    staticWallpaper: staticWallpaperReducer,
    liveCategory: liveCategoryReducer,
    liveWallpaper: liveWallpaperReducer,
    eliteCategory: eliteCategoryReducer,
    eliteWallpaper: eliteWallpaperReducer,
    products: ProductReducer,
    trends: TrendReducer,
    dashboard: DashboardReducer,
  },
});
export default store;
