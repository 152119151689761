import {
  Box,
  Flex,
  Heading,
  Input,
  Button,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Spinner,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormControl,
  FormLabel,
  useToast,
} from "@chakra-ui/react";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectTrends, selectError, selectLoading } from "../redux/trend/trendSelectors";
import { fetchTrends, addTrend, updateTrend, deleteTrend } from "../redux/trend/trendActions";
import DeleteModal from "../components/DeleteModal";

function Trend() {
  const [searchTerm, setSearchTerm] = useState("");
  const addProductModal = useDisclosure();
  const deleteProductModal = useDisclosure();

  // New state fields for form inputs
  const [device_id, setDeviceId] = useState(""); // State for device ID
  const [image_id, setImageId] = useState(""); // State for image ID
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [productToDelete, setProductToDelete] = useState(null);

  const dispatch = useDispatch();
  const trends = useSelector(selectTrends);
  const loading = useSelector(selectLoading);
  const error = useSelector(selectError);
  const toast = useToast();

  useEffect(() => {
    dispatch(fetchTrends());
  }, [dispatch]);

  useEffect(() => {
    if (error) {
      toast({
        title: "Trend Error",
        description: error,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }
  }, [toast, error]);

  const handleAddProduct = async () => {
    const formData = {
      deviceid: device_id,
      imageid: image_id,
    };

    try {
      const response = selectedProduct ? await dispatch(updateTrend(selectedProduct?.id, formData)) : await dispatch(addTrend(formData)); // Changed to `addTrend`

      toast({
        title: "Trend",
        description: response.message,
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    } catch (error) {
      toast({
        title: "Error adding trend.",
        description: error,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    } finally {
      setDeviceId("");
      setImageId("");
      addProductModal.onClose();
    }
  };

  const handleEditProduct = (product) => {
    setSelectedProduct(product);
    setDeviceId(product.deviceid);
    setImageId(product.imageid); 
    addProductModal.onOpen();
  };

  const openDeleteModal = (id) => {
    setProductToDelete(id);
    deleteProductModal.onOpen();
  };

  const handleDeleteProduct = async () => {
    try {
      const response = await dispatch(deleteTrend(productToDelete)); // Changed to `deleteTrend`
      toast({
        title: response.message,
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    } catch (error) {
      // Handle error
    } finally {
      deleteProductModal.onClose();
    }
  };

  const filteredProducts = trends?.filter(
    (product) => product.deviceid.toLowerCase().includes(searchTerm.toLowerCase()) || product.imageid.toLowerCase().includes(searchTerm.toLowerCase()) // Filtering by image ID
  );

  return (
    <Box p={5} bg="gray.100" minHeight="calc(100vh - 40px)">
      <Flex justify="space-between" align="center" mb={6}>
        <Heading as="h2" size="xl" color="teal.600">
          Trend Management
        </Heading>
        <Button colorScheme="teal" variant="solid" _hover={{ bg: "teal.700" }} onClick={addProductModal.onOpen}>
          Add Trend
        </Button>
      </Flex>

      <Input
        placeholder="Search trends..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        mb={4}
        borderColor="teal.300"
        _focus={{ borderColor: "teal.500", boxShadow: "outline" }}
      />

      {loading ? (
        <Flex justify={"center"} mt={4}>
          <Spinner size="lg" color="teal.500" />
        </Flex>
      ) : error ? (
        <Box color="red.500">{error}</Box>
      ) : trends.length === 0 ? (
        <Box>No trends found</Box>
      ) : (
        <Box overflowY="auto" maxH="calc(100vh - 200px)" borderWidth="1px" borderRadius="md" bg="white">
          <Table variant="simple" size="sm">
            <Thead>
              <Tr>
                <Th>Device ID</Th>
                <Th>Image ID</Th> {/* Column for image ID */}
                <Th>Actions</Th>
              </Tr>
            </Thead>
            <Tbody>
              {filteredProducts?.map((product) => (
                <Tr key={product.id}>
                  <Td>{product.deviceid}</Td>
                  <Td>{product.imageid}</Td> {/* Displaying image ID */}
                  <Td>
                    <Button size="sm" colorScheme="blue" onClick={() => handleEditProduct(product)}>
                      Edit
                    </Button>
                    <Button size="sm" colorScheme="red" onClick={() => openDeleteModal(product.id)} ml={2}>
                      Delete
                    </Button>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Box>
      )}

      {/* Modal for Adding Trend */}
      <Modal
        isOpen={addProductModal.isOpen}
        onClose={() => {
          setDeviceId("");
          setImageId("");
          addProductModal.onClose();
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{selectedProduct ? "Edit Trend" : "Add New Trend"}</ModalHeader>
          <ModalBody>
            <FormControl>
              <FormLabel>Device ID</FormLabel>
              <Input value={device_id} onChange={(e) => setDeviceId(e.target.value)} placeholder="Enter device ID" />
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>Image ID</FormLabel>
              <Input value={image_id} onChange={(e) => setImageId(e.target.value)} placeholder="Enter image ID" />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="teal" onClick={handleAddProduct} isDisabled={!device_id || !image_id} isLoading={loading}>
              {selectedProduct ? "Update" : "Add"}
            </Button>
            <Button
              onClick={() => {
                setDeviceId("");
                setImageId("");
                addProductModal.onClose();
              }}
              ml={3}
            >
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Modal for Delete Confirmation */}
      <DeleteModal modal={deleteProductModal} onDelete={handleDeleteProduct} loading={loading} />
    </Box>
  );
}

export default Trend;
