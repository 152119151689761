import axiosInstance from "../../api/axiosInstance";
import { fetchCategoriesRequest, fetchCategoriesSuccess, fetchCategoriesFailure } from "./liveCategorySlice";

// Action to fetch categories
export const fetchCategories = () => async (dispatch) => {
  dispatch(fetchCategoriesRequest());
  try {
    const response = await axiosInstance.get("/live-categories");
    dispatch(fetchCategoriesSuccess(response.data));
    return response.data; // Return response data
  } catch (error) {
    dispatch(fetchCategoriesFailure(error.message));
  }
};

// Action to add a new category
export const addCategory = (categoryData) => async (dispatch, getState) => {
  dispatch(fetchCategoriesRequest());
  try {
    const response = await axiosInstance.post("/live-categories", categoryData);
    const newCategory = response.data;
    const { categories } = getState().liveCategory;
    dispatch(fetchCategoriesSuccess([newCategory, ...categories]));
    return { message: "Category added successfully!" };
  } catch (error) {
    dispatch(fetchCategoriesFailure(error.message));
  }
};

// Action to update category
export const updateCategory = (id, categoryData) => async (dispatch, getState) => {
  dispatch(fetchCategoriesRequest());
  try {
    console.log(id, categoryData)
    const response = await axiosInstance.put(`/live-categories/${id}`, categoryData);
    const updatedCategory = response.data;
    const { categories } = getState().liveCategory;

    console.log(updatedCategory, categories);

    const updatedCategories = categories.map((category) => (category.id === updatedCategory.id ? updatedCategory : category));

    dispatch(fetchCategoriesSuccess(updatedCategories));

    return { message: "Category updated successfully!" };
  } catch (error) {
    dispatch(fetchCategoriesFailure(error.message));
  }
};

// Action to delete a category
export const deleteCategory = (categoryId) => async (dispatch, getState) => {
  dispatch(fetchCategoriesRequest());
  try {
    await axiosInstance.delete("/live-categories/" + categoryId);
    const { categories } = getState().liveCategory; // Get the current state of categories
    const updatedCategories = categories.filter((category) => category.id !== categoryId); // Filter out the deleted category
    dispatch(fetchCategoriesSuccess(updatedCategories)); // Update the state with the new list
    return { message: "Category deleted successfully!" };
  } catch (error) {
    dispatch(fetchCategoriesFailure(error.message));
  }
};
