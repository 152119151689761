import axiosInstance from "../../api/axiosInstance";
import { fetchDashboardSuccess, fetchDashboardRequest, fetchDashboardFailure } from "./dashboardSlice";

export const fetchCountWallpaper = () => async (dispatch) => {
  dispatch(fetchDashboardRequest());
  try {
    const response = await axiosInstance.get("/dashboard/counts");
    dispatch(fetchDashboardSuccess(response.data));
    return response.data; 
  } catch (error) {
    dispatch(fetchDashboardFailure(error.message));
  }
};
