import React, { useEffect } from "react";
import { Box, Grid, Heading, Text, Container, Flex, Icon, Spacer, useColorModeValue, Table, Thead, Tbody, Tr, Th, Td, Spinner } from "@chakra-ui/react";
import { FaImage, FaList, FaThLarge, FaStar } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { fetchCountWallpaper } from "../redux/dashboard/dashboardActions";
import { formatFileSize } from "../utils";

const DashboardPage = () => {
  const dispatch = useDispatch();
  const { counts, loading } = useSelector((state) => state.dashboard);

  useEffect(() => {
    dispatch(fetchCountWallpaper());
  }, [dispatch]);

  const stats = [
    { title: "Static Wallpaper Count", count: counts?.staticWallpaperCount || 0, icon: FaImage },
    { title: "Static Category Count", count: counts?.staticCategoryCount || 0, icon: FaList },
    { title: "Live Wallpaper Count", count: counts?.liveWallpaperCount || 0, icon: FaThLarge },
    { title: "Live Category Count", count: counts?.liveCategoryCount || 0, icon: FaList },
    { title: "Elite Category Count", count: counts?.eliteCategoryCount || 0, icon: FaStar },
    { title: "Elite Wallpaper Count", count: counts?.eliteWallpaperCount || 0, icon: FaStar },
  ];

  const cardBg = useColorModeValue("white", "gray.800");
  const cardShadow = useColorModeValue("lg", "dark-lg");
  const cardTextColor = useColorModeValue("gray.700", "white");
  const cardCountColor = useColorModeValue("indigo.600", "indigo.300");
  const iconBgGradient = useColorModeValue("linear(to-r, teal.400, blue.500)", "linear(to-r, purple.500, pink.600)");

  const countFontFamily = "Poppins, sans-serif";
  const countFontSize = { base: "3xl", md: "4xl" };

  if (loading) {
    return (
      <Box display={"flex"} justifyContent={"center"} alignItems={"center"} minHeight={"100vh"}>
        <Spinner size={"xl"} thickness="4px" speed="0.65s" emptyColor="gray.200" color="blue.500" />
      </Box>
    );
  }
  return (
    <Container maxW="container.xl" height="calc(100vh - 50px)" py={2} overflowY="auto">
      {/* Stats Cards */}
      <Heading as="h3" size="lg" mb={2} color={cardTextColor}>
          Dashboard
        </Heading>
      <Grid templateColumns={{ base: "1fr", md: "repeat(2, 1fr)", lg: "repeat(3, 1fr)" }} gap={6}>
        {stats.map((stat, index) => (
          <Box
            key={index}
            bg={cardBg}
            boxShadow={cardShadow}
            borderRadius="lg"
            p={6}
            transition="transform 0.3s ease, box-shadow 0.3s ease"
            _hover={{ transform: "translateY(-10px)", boxShadow: "2xl" }}
          >
            <Flex direction="column" height="100%">
              {/* Title on the left */}
              <Flex align="center">
                <Heading as="h2" size="md" color={cardTextColor}>
                  {stat.title}
                </Heading>

                {/* Icon on the right with gradient background */}
                <Box ml="auto" bgGradient={iconBgGradient} borderRadius="full" pl={2} pr={2} pt={2} pb={0.5}>
                  <Icon as={stat.icon} w={5} h={5} color="white" />
                </Box>
              </Flex>

              <Spacer />

              {/* Count at the bottom left */}
              <Text fontSize={countFontSize} fontWeight="extrabold" color={cardCountColor} fontFamily={countFontFamily} textAlign="left" mt={4}>
                {stat.count}
              </Text>
            </Flex>
          </Box>
        ))}
      </Grid>

      {/* Latest Wallpapers Section */}
      <Box mt={10} bg={cardBg} boxShadow={cardShadow} borderRadius="lg" p={6}>
        {/* Static Wallpapers Table */}
        <Heading as="h4" size="md" mb={2} color={cardTextColor}>
          Latest Static Wallpapers
        </Heading>
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>ID</Th>
              <Th>Category Name</Th>
              <Th>Image Name</Th>
              <Th>Size</Th>
              <Th>Dimension</Th>
              <Th>Date Uploaded</Th>
            </Tr>
          </Thead>
          <Tbody>
            {counts?.latestStaticWallpaer.map((wallpaper) => (
              <Tr key={wallpaper.id}>
                <Td>{wallpaper.id}</Td>
                <Td>{wallpaper.catName}</Td>
                <Td>{wallpaper.imageName}</Td>
                <Td>{formatFileSize(wallpaper.size)}</Td>
                <Td>{wallpaper.dimension}</Td>
                <Td>{new Date(wallpaper.createdAt).toLocaleDateString()}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>
      <Box mt={10} bg={cardBg} boxShadow={cardShadow} borderRadius="lg" p={6}>
        {/* Live Wallpapers Table */}
        <Heading as="h4" size="md" mt={6} mb={2} color={cardTextColor}>
          Latest Live Wallpapers
        </Heading>
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>ID</Th>
              <Th>Category Name</Th>
              <Th>Video Name</Th>
              <Th>Thumbnail Name</Th>
              <Th>Size</Th>
              <Th>Dimension</Th>
              <Th>Date Uploaded</Th>
            </Tr>
          </Thead>
          <Tbody>
            {counts?.latestLiveWallpaer.map((wallpaper) => (
              <Tr key={wallpaper.id}>
                <Td>{wallpaper.id}</Td>
                <Td>{wallpaper.category?.catName}</Td>
                <Td>{wallpaper.new_name}</Td>
                <Td>{wallpaper.thumbnail}</Td>
                <Td>{formatFileSize(wallpaper.size)}</Td>
                <Td>{wallpaper.dimension}</Td>
                <Td>{new Date(wallpaper.createdAt).toLocaleDateString()}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>
      <Box mt={10} bg={cardBg} boxShadow={cardShadow} borderRadius="lg" p={6}>
        {/* Elite Wallpapers Table */}
        <Heading as="h4" size="md" mt={6} mb={2} color={cardTextColor}>
          Latest Elite Wallpapers
        </Heading>
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>ID</Th>
              <Th>Category Name</Th>
              <Th>Image Name</Th>
              <Th>Size</Th>
              <Th>Dimension</Th>
              <Th>Date Uploaded</Th>
            </Tr>
          </Thead>
          <Tbody>
            {counts?.latestEliteWallpaper.map((wallpaper) => (
              <Tr key={wallpaper.id}>
                <Td>{wallpaper.id}</Td>
                <Td>{wallpaper.catname}</Td>
                <Td>{wallpaper.imagename}</Td>
                <Td>{formatFileSize(wallpaper.size)}</Td>
                <Td>{wallpaper.dimension}</Td>
                <Td>{new Date(wallpaper.createdAt).toLocaleDateString()}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>
    </Container>
  );
};

export default DashboardPage;
