// theme.js
import { extendTheme } from "@chakra-ui/react";

// Custom theme overrides
const customTheme = extendTheme({
  config: {
    initialColorMode: "light",
    useSystemColorMode: false,
  },
  colors: {
    brand: {
      50: "#e3f9f9",
      100: "#c1f1f1",
      200: "#99e3e3",
      300: "#33b2b2",
      400: "#009292",
      500: "#007575",
      600: "#005757",
      700: "#003737",
      800: "#001f1f",
      900: "#000000",
    },
    gray: {
      50: "#f9f9f9",
      100: "#eaeaea",
      200: "#d3d3d3",
      300: "#b0b0b0",
      400: "#8c8c8c",
      500: "#707070",
      600: "#585858",
      700: "#444444",
      800: "#303030",
      900: "#1c1c1c",
    },
  },
  fonts: {
    heading: "Poppins, sans-serif",
    body: "Roboto, sans-serif",
  },
  styles: {
    global: (props) => ({
      body: {
        bg: props.colorMode === "dark" ? "gray.800" : "gray.50", // Background color
        color: props.colorMode === "dark" ? "gray.200" : "gray.800", // Text color
      },
    }),
  },
});

export default customTheme;
