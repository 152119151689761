import React, { useState } from "react";
import {
  Box,
  Button,
  Heading,
  Text,
  Stack,
  Divider,
  FormControl,
  FormLabel,
  Switch,
  useToast,
} from "@chakra-ui/react";
import axiosInstance from "../api/axiosInstance";

const SettingsPage = () => {
  const toast = useToast(); // Initialize the toast notification
  const [loadingStatic, setLoadingStatic] = useState(false); // Loading state for static wallpapers
  const [loadingLive, setLoadingLive] = useState(false); // Loading state for live wallpapers
  const [loadingElite, setLoadingElite] = useState(false); // Loading state for elite wallpapers

  // Function to handle exporting static wallpapers
  const handleExportStaticWallpapers = async () => {
    setLoadingStatic(true);
    try {
      const response = await axiosInstance.get("/setting/export/static-wallpapers", {
        responseType: "blob",
      });
      downloadFile(response.data, "static_wallpapers.xlsx");
      toast({
        title: "Download started.",
        description: "Your static wallpapers are being downloaded.",
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    } catch (error) {
      handleError("static wallpapers");
    } finally {
      setLoadingStatic(false);
    }
  };

  // Function to handle exporting live wallpapers
  const handleExportLiveWallpapers = async () => {
    setLoadingLive(true);
    try {
      const response = await axiosInstance.get("/setting/export/live-wallpapers", {
        responseType: "blob",
      });
      downloadFile(response.data, "live_wallpapers.xlsx");
      toast({
        title: "Download started.",
        description: "Your live wallpapers are being downloaded.",
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    } catch (error) {
      handleError("live wallpapers");
    } finally {
      setLoadingLive(false);
    }
  };

  // Function to handle exporting elite wallpapers
  const handleExportEliteWallpapers = async () => {
    setLoadingElite(true);
    try {
      const response = await axiosInstance.get("/setting/export/elite-wallpapers", {
        responseType: "blob",
      });
      downloadFile(response.data, "elite_wallpapers.xlsx");
      toast({
        title: "Download started.",
        description: "Your elite wallpapers are being downloaded.",
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    } catch (error) {
      handleError("elite wallpapers");
    } finally {
      setLoadingElite(false);
    }
  };

  // Helper function to handle file download
  const downloadFile = (data, filename) => {
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  // Helper function to handle error notifications
  const handleError = (type) => {
    toast({
      title: "Download failed.",
      description: `An error occurred while downloading ${type}.`,
      status: "error",
      duration: 3000,
      isClosable: true,
      position: "top-right",
    });
  };

  return (
    <Box p={6}>
      <Heading mb={4}>Settings</Heading>
      <Divider mb={6} />

      {/* Export Options */}
      <Box mb={8}>
        <Heading size="md" mb={4}>
          Export Options
        </Heading>
        <Text mb={2}>
          Choose which wallpapers to export and manage your preferences.
        </Text>
        <Stack spacing={4}>
          <Button
            colorScheme="teal"
            variant="solid"
            isLoading={loadingStatic} // Loading state for static wallpapers
            onClick={handleExportStaticWallpapers} // Call the API on click
            loadingText="Exporting..." // Text to show while loading
          >
            Export Static Wallpapers
          </Button>
          <Button
            colorScheme="teal"
            variant="solid"
            isLoading={loadingLive} // Loading state for live wallpapers
            onClick={handleExportLiveWallpapers} // Call the API on click
            loadingText="Exporting..." // Text to show while loading
          >
            Export Live Wallpapers
          </Button>
          <Button
            colorScheme="teal"
            variant="solid"
            isLoading={loadingElite} // Loading state for elite wallpapers
            onClick={handleExportEliteWallpapers} // Call the API on click
            loadingText="Exporting..." // Text to show while loading
          >
            Export Elite Wallpapers
          </Button>
        </Stack>
      </Box>

      {/* Settings */}
      <Box>
        <Heading size="md" mb={4}>
          General Settings
        </Heading>
        <FormControl display="flex" alignItems="center" mb={4}>
          <FormLabel htmlFor="notifications" mb="0">
            Enable Notifications
          </FormLabel>
          <Switch id="notifications" colorScheme="teal" />
        </FormControl>
        <FormControl display="flex" alignItems="center" mb={4}>
          <FormLabel htmlFor="dark-mode" mb="0">
            Dark Mode
          </FormLabel>
          <Switch id="dark-mode" colorScheme="teal" />
        </FormControl>
      </Box>

      {/* Additional Settings */}
      <Box>
        <Heading size="md" mb={4}>
          Additional Settings
        </Heading>
        <Text mb={2}>Manage your preferences here:</Text>
        <Stack spacing={4}>
          <Button colorScheme="gray" variant="outline">
            Reset Settings
          </Button>
          <Button colorScheme="red" variant="outline">
            Delete Account
          </Button>
        </Stack>
      </Box>
    </Box>
  );
};

export default SettingsPage;
