import {
  Box,
  Flex,
  Text,
  IconButton,
  Badge,
  Spinner,
  Center,
  useToast,
  Icon,
  Tooltip,
  Button,
  useDisclosure,
  SimpleGrid,
  Switch,
  Select,
} from "@chakra-ui/react";
import { EditIcon, DeleteIcon } from "@chakra-ui/icons";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { selectWallpapers } from "../redux/eliteWallpaper/eliteWallpaperSelector";
import { selectError, selectLoading } from "../redux/eliteCategory/eliteCategorySelectors";
import { fetchWallpapers } from "../redux/eliteWallpaper/eliteWallpaperActions";
import { useEffect, useState, useRef } from "react";
import { formatFileSize } from "../utils";
import { FaDownload, FaEye, FaHeart } from "react-icons/fa";
import axiosInstance from "../api/axiosInstance";
import AlertDialogComp from "../components/AlertDialogComp";
import { FixedSizeList as List } from "react-window";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import EliteImageUploadModal from "../components/EliteImageUploadModal";

const WallpaperItem = ({ wallpaper, onEdit, onDelete }) => (
  <Box borderWidth="1px" borderRadius="lg" overflow="hidden" boxShadow="md" position="relative">
    <Box position="relative">
      <LazyLoadImage
        src={process.env.REACT_APP_MEDIA_URL + wallpaper.imagename}
        alt={wallpaper.imagename}
        effect="blur"
        width="100%"
        style={{ maxHeight: "200px", height: "100%", objectFit: "cover", minHeight: "200px" }}
      />
      <Box position="absolute" bottom={2} left={2} bg="rgba(0, 0, 0, 0.6)" color="white" px={2} py={1} borderRadius="md" fontSize="sm">
        {wallpaper.dimension}
      </Box>
      <Box position="absolute" bottom={2} right={2} bg="rgba(0, 0, 0, 0.6)" color="white" px={2} py={1} borderRadius="md" fontSize="sm">
        {formatFileSize(wallpaper.size)}
      </Box>
    </Box>

    <Flex position="absolute" top={2} right={2}>
      <IconButton aria-label="Edit Wallpaper" icon={<EditIcon />} colorScheme="teal" size="sm" mr={2} onClick={() => onEdit(wallpaper)} />
      <IconButton aria-label="Delete Wallpaper" icon={<DeleteIcon />} colorScheme="red" size="sm" onClick={() => onDelete(wallpaper.id)} />
    </Flex>

    <Box p={4}>
      <Flex justifyContent="space-between" alignItems="center" mb={2}>
        <Badge colorScheme="teal">{wallpaper?.catname}</Badge>
        <Badge
            colorScheme={getSubscriptionBadgeColor(wallpaper?.subscriptionType)}
          >
            {wallpaper?.subscriptionType}
          </Badge>
        <Text fontSize="sm" color="gray.500">
          {new Date(wallpaper.createdAt).toLocaleDateString()}
        </Text>
      </Flex>

      <Text fontWeight="bold" fontSize="md" mb={2}>
        <Tooltip label={wallpaper.new_name} fontSize="md">
          <span>{wallpaper.new_name}</span>
        </Tooltip>
      </Text>

      <Flex justifyContent="space-between" alignItems="center">
        <Flex alignItems="center" mr={4}>
          <Icon as={FaHeart} color="red.500" mr={1} />
          <Text fontSize="sm">{wallpaper.likes}</Text>
        </Flex>
        <Flex alignItems="center" mr={4}>
          <Icon as={FaEye} color="gray.500" mr={1} />
          <Text fontSize="sm">{wallpaper.views}</Text>
        </Flex>
        <Flex alignItems="center">
          <Icon as={FaDownload} color="blue.500" mr={1} />
          <Text fontSize="sm">{wallpaper.downloads}</Text>
        </Flex>
      </Flex>
    </Box>
  </Box>
);

const getSubscriptionBadgeColor = (type) => {
  switch (type) {
    case "Free":
      return "blue";
    case "Pro":
      return "purple";
    case "Ads":
      return "orange";
    case "Seasonal":
      return "yellow";
    case "Time":
      return "teal";
    case "Flash":
      return "pink";
    case "Once":
      return "cyan";
    default:
      return "gray"; // Default color if type is unknown
  }
};

const EliteWallpaper = () => {
  const { catName } = useParams();
  const dispatch = useDispatch();
  const wallpapers = useSelector(selectWallpapers);
  const loading = useSelector(selectLoading);
  const error = useSelector(selectError);
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [editData, seteditData] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const { isOpen: isDeleteOpen, onOpen: onDeleteOpen, onClose: onDeleteClose } = useDisclosure();
  const cancelRef = useRef();
  const [deleteData, setDeleteData] = useState(null);

  // State for sorting
  const [sortOrder, setSortOrder] = useState("asc");
  const [sortCriteria, setSortCriteria] = useState("likes"); // Default sorting by likes

  const ITEM_HEIGHT = 340; // Set item height
  const COLUMN_COUNT = 5; // Number of columns
  const ROW_HEIGHT = ITEM_HEIGHT; // Total height of each row
  const visibleItemsCount = Math.ceil(wallpapers.length / COLUMN_COUNT);

  useEffect(() => {
    dispatch(fetchWallpapers(catName));
  }, [dispatch, catName]);

  useEffect(() => {
    if (error) {
      toast({
        title: "Error fetching categories.",
        description: error,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }
  }, [toast, error]);

  const handleEditWallpaper = (id) => {
    seteditData(id);
    onOpen();
  };

  const handleDeleteWallpaper = (id) => {
    setDeleteData(id);
    onDeleteOpen();
  };

  const confirmDeleteWallpaper = async () => {
    setIsDeleting(true);
    try {
      await axiosInstance.delete(`/elite-wallpaper/${deleteData}`);
      toast({
        title: "Wallpaper deleted.",
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      dispatch(fetchWallpapers(catName));
      onDeleteClose();
    } catch (error) {
      console.log(error);
      toast({
        title: "Error deleting wallpaper.",
        description: error.response?.data?.message || "An error occurred.",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    } finally {
      setIsDeleting(false);
    }
  };

  // Sorting function
  const sortedWallpapers = [...wallpapers].sort((a, b) => {
    const aValue = sortCriteria === "likes" ? a.likes : sortCriteria === "views" ? a.views : a.downloads;
    const bValue = sortCriteria === "likes" ? b.likes : sortCriteria === "views" ? b.views : b.downloads;

    return sortOrder === "asc" ? aValue - bValue : bValue - aValue;
  });

  if (loading) {
    return (
      <Center height="100vh">
        <Spinner size="xl" color="teal.500" />
      </Center>
    );
  }

  return (
    <Box p={5}>
      <Flex justify={"space-between"} alignItems={"center"} mb={2}>
        <Text fontSize="xl" fontWeight="bold">
          {catName} {"(" + sortedWallpapers?.length + ")"}
        </Text>
        <Button variant={"solid"} colorScheme="teal" _hover={{ bg: "teal.700" }} onClick={onOpen}>
          Add Wallpaper
        </Button>
      </Flex>

      <Flex mb={4} alignItems="center">
        <Text mr={2}>Sort by:</Text>
        <Select value={sortCriteria} onChange={(e) => setSortCriteria(e.target.value)} width="200px" mr={4}>
          <option value="likes">Likes</option>
          <option value="views">Views</option>
          <option value="downloads">Downloads</option>
          <option value="">None</option>
        </Select>
        <Text mr={2}>Order:</Text>
        <Switch isChecked={sortOrder === "desc"} onChange={() => setSortOrder(sortOrder === "asc" ? "desc" : "asc")} colorScheme="teal" mr={4} />
        <Text>{sortOrder === "asc" ? "Ascending" : "Descending"}</Text>
      </Flex>

      <Box>
        {wallpapers.length === 0 ? (
          <Center height="50vh">
            <Text fontSize="xl" color="gray.500">
              No wallpapers available.
            </Text>
          </Center>
        ) : (
          <List height={window.innerHeight - 100} style={{ maxHeight: "calc(100vh - 130px)" }} itemCount={visibleItemsCount} itemSize={ROW_HEIGHT} width="100%">
            {({ index, style }) => {
              const startIndex = index * COLUMN_COUNT;
              const endIndex = Math.min(startIndex + COLUMN_COUNT, wallpapers.length);

              return (
                <div style={style}>
                  <SimpleGrid columns={{ base: 1, sm: 2, md: 3, lg: 4, xl: 5 }} gap={5}>
                    {Array.from({ length: endIndex - startIndex }, (_, i) => {
                      const wallpaperIndex = startIndex + i;
                      return (
                        wallpaperIndex < sortedWallpapers.length && (
                          <WallpaperItem
                            key={sortedWallpapers[wallpaperIndex].id}
                            wallpaper={sortedWallpapers[wallpaperIndex]}
                            onEdit={handleEditWallpaper}
                            onDelete={handleDeleteWallpaper}
                          />
                        )
                      );
                    })}
                  </SimpleGrid>
                </div>
              );
            }}
          </List>
        )}
      </Box>

      <AlertDialogComp
        isOpen={isDeleteOpen}
        onClose={onDeleteClose}
        isDeleting={isDeleting}
        cancelRef={cancelRef}
        title="Delete Wallpaper"
        body="Are you sure you want to delete this wallpaper?"
        isLoading={isDeleting}
        confirm={confirmDeleteWallpaper}
      />
      <EliteImageUploadModal isOpen={isOpen} onClose={onClose} editData={editData} seteditData={seteditData} catName={catName} />
    </Box>
  );
};

export default EliteWallpaper;
