import React, { useState, useCallback, useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Image,
  Box,
  Flex,
  IconButton,
  Text,
  Input,
  Progress,
  useToast,
  Select,
} from "@chakra-ui/react";
import { CloseIcon } from "@chakra-ui/icons";
import { useDispatch } from "react-redux";
import { addWallpaper, updateWallpaper } from "../redux/staticWallpaper/staticWallpaperActions";

const ImageThumbnail = React.memo(({ url, onRemove }) => (
  <Box position="relative" width="100px" height="100px">
    <Image src={url} alt="Preview" boxSize="100%" objectFit="cover" borderRadius="md" loading="lazy" />
    <IconButton
      aria-label="Remove Image"
      icon={<CloseIcon style={{ fontSize: "10px" }} />}
      position="absolute"
      top={0}
      right={0}
      zIndex={2}
      size="xs"
      colorScheme="red"
      onClick={onRemove}
    />
  </Box>
));

const ImageUploadModal = ({ isOpen, onClose, isEdit = false, seteditData, editData, catName }) => {
  const [files, setFiles] = useState([]);
  const [previewUrls, setPreviewUrls] = useState([]);
  const [isDragOver, setIsDragOver] = useState(false);
  const [progress, setProgress] = useState(0);
  const [uploading, setUploading] = useState(false);
  const dispatch = useDispatch();
  const [subscriptionType, setSubscriptionType] = useState('Free')
  const toast = useToast(); // Initialize toast

  useEffect(() => {
    console.log('@updated subscriptionType state:', subscriptionType); // Logs when `subscriptionType` state changes
  }, [subscriptionType]);

  useEffect(() => {
    if (editData) {
      setSubscriptionType(editData.subscriptionType)
      setPreviewUrls([process.env.REACT_APP_MEDIA_URL + editData.imageName]);
    }else {
      setSubscriptionType('')
    }
  }, [editData]);

  const handleFileChange = (selectedFiles) => {
    const newFiles = Array.from(selectedFiles);
    if (editData && (files.length >= 1 || previewUrls.length >= 1)) {
      setFiles(newFiles);
      setPreviewUrls(newFiles.map((file) => URL.createObjectURL(file)));
      return;
    }
    setFiles((prevFiles) => [...prevFiles, ...newFiles]);
    const urls = newFiles.map((file) => URL.createObjectURL(file));
    setPreviewUrls((prevUrls) => [...prevUrls, ...urls]);
  };
  const handleUpload = async () => {
    setUploading(true);
    let errors = []; // To track errors

    try {
      if (editData) {
        const formData = new FormData();
        formData.append('subscriptionType', subscriptionType)
        formData.append("staticWallpaper", files[0]);
        const response = await dispatch(updateWallpaper(editData.id, formData));

        // Check if the response contains an error
        if (!response.message) {
          errors.push("Failed to update wallpaper: " + (response?.message || "Unknown error"));
        }
      } else {
        let completedUploads = 0;

        for (const file of files) {
          const formData = new FormData();
          formData.append("staticWallpaper", file);
          formData.append("catName", catName);

          // Dispatch the addWallpaper action with formData
          const response = await dispatch(addWallpaper(formData));
          console.log(response.status);

          // Check if the response contains an error
          if (response.status !== 201) {
            const errorMessage = response.response.data?.errors ? response.response.data.errors[0]?.error : response.message || "Unknown error";
            errors.push(` ${errorMessage}`);
          }

          // Update progress
          completedUploads++;
          setProgress((completedUploads / files.length) * 100);
        }
      }

      console.log(errors);
      window.location.reload();
      // If there are no errors, reset the form
      if (errors.length === 0) {
        setSubscriptionType('')
        setFiles([]);
        setPreviewUrls([]);
        setProgress(0);
        seteditData(null);
        onClose(); // Close the modal only if there's no error

        // Show success toast
        toast({
          title: "Success!",
          description: "Wallpapers uploaded successfully.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      } else {
        // Show error toast with accumulated errors
        toast({
          title: "Upload Errors",
          description: `${errors.join("\n")}`,
          status: "error",
          duration: 8000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error("Error uploading wallpapers:", error);
      errors.push("Unexpected error: " + error.message);

      // Show error toast for unexpected error
      toast({
        title: "Unexpected Error",
        description: error.message,
        status: "error",
        duration: 8000,
        isClosable: true,
      });
    } finally {
      setUploading(false);
    }
  };

  const handleModalClose = () => {
    previewUrls.forEach((url) => URL.revokeObjectURL(url));
    setFiles([]);
    setPreviewUrls([]);
    setProgress(0);
    onClose();
    seteditData(null);
  };

  const handleRemoveImage = useCallback((index) => {
    setFiles((prevFiles) => {
      const newFiles = [...prevFiles];
      newFiles.splice(index, 1);
      return newFiles;
    });

    setPreviewUrls((prevUrls) => {
      const newUrls = [...prevUrls];
      newUrls.splice(index, 1);
      return newUrls;
    });
  }, []);

  const handleDeleteAll = () => {
    setFiles([]);
    setPreviewUrls([]);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    setIsDragOver(false);
    const droppedFiles = event.dataTransfer.files;
    handleFileChange(droppedFiles);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    setIsDragOver(true);
  };

  const handleDragLeave = () => {
    setIsDragOver(false);
  };

  return (
    <Modal isOpen={isOpen} onClose={handleModalClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{editData ? "Update New Wallpaper" : "Upload Wallpaper"}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
           {/* SubscriptionType Field */}
           <Box mt={4}>
            <Text>Subscription Type:</Text>
            <Select value={subscriptionType} onChange={(e) => {setSubscriptionType(e.target.value);         
            }}>
              <option value="" disabled>Select a subscription type</option>
              <option value="Free">Free</option>
              <option value="Pro">Pro</option>
              <option value="Ads">Ads</option> 
              <option value="Seasonal">Seasonal</option> 
              <option value="Time">Time</option> 
              <option value="Flash">Flash</option> 
              <option value="Once">Once</option>
            </Select>
          </Box>
          {/*  */}
          <br/>
          <Box
            p={4}
            borderWidth={2}
            borderColor={isDragOver ? "blue.400" : "gray.300"}
            borderStyle="dashed"
            borderRadius="md"
            textAlign="center"
            onDrop={handleDrop}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
          >
            <Text mb={2}>Drag and drop your images here, or click to select files</Text>
            <Input type="file" multiple={editData === null} accept="image/*" onChange={(e) => handleFileChange(e.target.files)} display="none" />
            <Button onClick={() => document.querySelector('input[type="file"]').click()} colorScheme="blue">
              Select Files
            </Button>
          </Box>
          <Box mt={4}>
            <Flex gap={"4"} flexWrap={"wrap"} height={"100%"} maxHeight={400} overflow={"auto"}>
              {previewUrls.map((url, index) => (
                <ImageThumbnail key={index} url={url} onRemove={() => handleRemoveImage(index)} />
              ))}
            </Flex>
          </Box>
          {uploading && !editData && (
            <Box mt={4}>
              <Text>Uploading... {progress.toFixed(0)}%</Text>
              <Progress value={progress} size="sm" colorScheme="green" />
            </Box>
          )}
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" onClick={handleUpload} isDisabled={ uploading}>
            {uploading ? "Uploading..." : "Upload"}
          </Button>
          <Button colorScheme="red" onClick={handleDeleteAll} ml={3} isDisabled={files.length === 0}>
            Delete All
          </Button>
          <Button onClick={handleModalClose} ml={3}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ImageUploadModal;
