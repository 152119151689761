import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Image,
  Box,
  IconButton,
  Text,
  Input,
  Progress,
  Select,
  RadioGroup,
  Radio,
  Stack,
} from "@chakra-ui/react";
import { CloseIcon } from "@chakra-ui/icons";
import { useDispatch } from "react-redux";
import { addWallpaper, updateWallpaper } from "../redux/liveWallpaper/liveWallpaperActions";

const ImageThumbnail = React.memo(({ url, onRemove }) => (
  <Box position="relative" width="100px" height="100px">
    <Image src={url} alt="Preview" boxSize="100%" objectFit="cover" borderRadius="md" loading="lazy" />
    <IconButton
      aria-label="Remove Image"
      icon={<CloseIcon style={{ fontSize: "10px" }} />}
      position="absolute"
      top={0}
      right={0}
      zIndex={2}
      size="xs"
      colorScheme="red"
      onClick={onRemove}
    />
  </Box>
));

const VideoPreview = ({ url, onRemove }) => (
  <Box position="relative" width="200px" overflow={"hidden"}>
    <video width="100%" height="100%" controls>
      <source src={url} type="video/mp4" />
      Your browser does not support the video tag.
    </video>
    <IconButton
      aria-label="Remove Video"
      icon={<CloseIcon style={{ fontSize: "10px" }} />}
      position="absolute"
      top={0}
      right={0}
      zIndex={2}
      size="xs"
      colorScheme="red"
      onClick={onRemove}
    />
  </Box>
);

const LiveImageUploadModal = ({ isOpen, onClose, isEdit = false, seteditData, editData, catName, categories }) => {
  const [thumbnailFile, setThumbnailFile] = useState(null);
  const [videoFile, setVideoFile] = useState(null);
  const [thumbnailPreviewUrl, setThumbnailPreviewUrl] = useState(null);
  const [videoPreviewUrl, setVideoPreviewUrl] = useState(null);
  const [progress, setProgress] = useState(0);
  const [uploading, setUploading] = useState(false);
  const [category, setCategory] = useState("");
  const [status, setStatus] = useState("inactive");
  const [pro, setPro] = useState("");
  const [subscriptionType, setSubscriptionType] = useState('Free')
  const [tags, setTags] = useState("");
  const [quality, setQuality] = useState("");
  const dispatch = useDispatch();

  console.log(categories);

  useEffect(() => {
    console.log('@updated pro state:', pro); // Logs when `pro` state changes
  }, [pro]);
  
  useEffect(() => {
    console.log('@updated subscriptionType state:', subscriptionType); // Logs when `subscriptionType` state changes
  }, [subscriptionType]);

  useEffect(() => {
    if (editData) {     
      console.log('@editData', editData);
       
      setThumbnailPreviewUrl(process.env.REACT_APP_MEDIA_URL + "/liveWallpapers/compress/" + editData.thumbnail);
      setVideoPreviewUrl(process.env.REACT_APP_MEDIA_URL + "/liveWallpapers/hd/" + editData.livewallpaper);
      setCategory(editData.categoryId);
      setStatus(editData.status);
      setPro(editData.pro);
      setSubscriptionType(editData.subscriptionType)
      setTags(editData.tags === null ? "" : editData.tags);
    } else {
      setThumbnailPreviewUrl(null);
      setVideoFile(null);
      setVideoPreviewUrl(null);
      setCategory("");
      setPro("");
      setSubscriptionType('')
      setTags("");
      setStatus("inactive");
      setQuality("");
    }
  }, [editData]);

  const handleFileChange = (file, setFile, setPreviewUrl, isVideo = false) => {
    setFile(file);
    setPreviewUrl(URL.createObjectURL(file));
  };

  const handleUpload = async () => {
    setUploading(true);
    try {
      const formData = new FormData();
      if (thumbnailFile) formData.append("liveWallpaperThumbnail", thumbnailFile);
      if (videoFile) formData.append("liveWallpaperVideo", videoFile);
      formData.append("categoryId", Number(category) || Number(catName));
      formData.append("status", status);
      formData.append("pro", Number(pro));
      formData.append('subscriptionType', subscriptionType)
      formData.append("tags", tags);
      formData.append("quality", quality);

      if (editData) {
        const response = await dispatch(updateWallpaper(editData.id, formData));
        console.log(response);
      } else {
        const response = await dispatch(addWallpaper(formData));
        console.log(response);
      }
      window.location.reload();

      // Reset fields
      setThumbnailFile(null);
      setVideoFile(null);
      setThumbnailPreviewUrl(null);
      setVideoPreviewUrl(null);
      setProgress(0);
      setQuality("");
      setPro("0");
      setSubscriptionType('')
      setStatus("inactive");
      setCategory("");
      seteditData(null);
      onClose();
    } catch (error) {
      console.error("Error uploading files:", error);
    } finally {
      setUploading(false);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent overflow={"auto"} height={"calc(100vh - 100px)"}>
        <ModalHeader>{editData ? "Update Wallpaper" : "Upload Wallpaper"}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {/* Category Selection */}
          <Box mt={4}>
            <Text>Category:</Text>
            <Select value={category} onChange={(e) => setCategory(e.target.value)} placeholder="Select Category">
            
              {categories?.map((item) => {
                return <option value={item.id}>{item.catName}</option>;
              })}
              {/* Add more categories as needed */}
            </Select>
          </Box>
        
          

          {/* Status Field */}
          <Box mt={4}>
            <Text>Status:</Text>
            <RadioGroup onChange={setStatus} value={status}>
              <Stack direction="row">
                <Radio value="active">Active</Radio>
                <Radio value="inactive">Inactive</Radio>
              </Stack>
            </RadioGroup>
          </Box>

          {/* SubscriptionType Field */}
          <Box mt={4}>
            <Text>Subscription Type:</Text>
            <Select value={subscriptionType} onChange={(e) => {setSubscriptionType(e.target.value);         
            }}>
              <option value="" disabled>Select a subscription type</option>
              <option value="Free">Free</option>
              <option value="Pro">Pro</option>
              <option value="Ads">Ads</option> 
              <option value="Seasonal">Seasonal</option> 
              <option value="Time">Time</option> 
              <option value="Flash">Flash</option> 
              <option value="Once">Once</option>
            </Select>
          </Box>
 
          <Box mt={4}>
            <Text>Quality:</Text>
            <Select value={quality} onChange={(e) => setQuality(e.target.value)}>
              <option value="">None</option>
              <option value="HD">HD</option>
              <option value="4K">4K</option>
              <option value="8K">8K</option>
            </Select>
          </Box>

          {/* Tags Input */}
          <Box mt={4}>
            <Text>Tags:</Text>
            <Input placeholder="Enter tags separated by commas" value={tags} onChange={(e) => setTags(e.target.value)} />
          </Box>

          {/* Thumbnail Upload */}
          <Box mt={4}>
            <Text>Upload Thumbnail:</Text>
            <Input type="file" accept="image/*" onChange={(e) => handleFileChange(e.target.files[0], setThumbnailFile, setThumbnailPreviewUrl)} />
          </Box>
          {thumbnailPreviewUrl && (
            <Box mt={4}>
              <Text>Thumbnail Preview:</Text>
              <ImageThumbnail url={thumbnailPreviewUrl} onRemove={() => handleFileChange(null, setThumbnailFile, setThumbnailPreviewUrl)} />
            </Box>
          )}

          {/* Video Upload */}
          <Box mt={4}>
            <Text>Upload Video:</Text>
            <Input type="file" accept="video/*" onChange={(e) => handleFileChange(e.target.files[0], setVideoFile, setVideoPreviewUrl, true)} />
          </Box>
          {videoPreviewUrl && (
            <Box mt={4}>
              <Text>Video Preview:</Text>
              <VideoPreview url={videoPreviewUrl} onRemove={() => handleFileChange(null, setVideoFile, setVideoPreviewUrl)} />
            </Box>
          )}

          {/* Upload Progress */}
          {uploading && (
            <Box mt={4}>
              <Text>Uploading... {progress.toFixed(0)}%</Text>
              <Progress value={progress} size="sm" colorScheme="green" />
            </Box>
          )}
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" onClick={handleUpload} isDisabled={editData ? false : uploading || (!thumbnailFile && !videoFile)}>
            {uploading ? "Uploading..." : "Upload"}
          </Button>
          <Button onClick={onClose} ml={3}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default LiveImageUploadModal;
