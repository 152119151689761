import { Button, Image, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from "@chakra-ui/react";
import React from "react";

const ViewImageModal = ({ setViewImage, modal, viewImage }) => {
  return (
    <Modal
      isOpen={modal.isOpen}
      onClose={() => {
        setViewImage(null);
        modal.onClose();
      }}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Image Preview</ModalHeader>
        <ModalBody>{viewImage && <Image src={viewImage} alt="View" />}</ModalBody>
        <ModalFooter>
          <Button
            onClick={() => {
              setViewImage(null);
              modal.onClose();
            }}
          >
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ViewImageModal;
