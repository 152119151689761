import { createSlice } from "@reduxjs/toolkit";

const liveWallpaperSlice = createSlice({
  name: "liveWallpaper",
  initialState: {
    wallpapers: [],
    loading: false,
    error: null,
  },
  reducers: {
    fetchWallpaperRequest(state) {
      state.loading = true;
    },
    fetchWallpaperSuccess(state, action) {
      state.loading = false;
      state.wallpapers = action.payload;
    },
    fetchWallpaperFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { fetchWallpaperRequest, fetchWallpaperSuccess, fetchWallpaperFailure } = liveWallpaperSlice.actions;

export default liveWallpaperSlice.reducer;
