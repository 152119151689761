import React, { useState, useCallback, useEffect, useRef } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Image,
  Box,
  Flex,
  IconButton,
  Text,
  Input,
  Progress,
  useToast,
  Select,
} from "@chakra-ui/react";
import { CloseIcon } from "@chakra-ui/icons";
import { useDispatch } from "react-redux";
import { addWallpaper, updateWallpaper } from "../redux/eliteWallpaper/eliteWallpaperActions";

// ImageThumbnail component for both image and thumbnail previews
const ImageThumbnail = React.memo(({ url, onRemove, label }) => (
  <Box position="relative" width="100px" height="100px">
    <Image src={url} alt={`${label} Preview`} boxSize="100%" objectFit="cover" borderRadius="md" loading="lazy" />
    <IconButton
      aria-label={`Remove ${label}`}
      icon={<CloseIcon style={{ fontSize: "10px" }} />}
      position="absolute"
      top={0}
      right={0}
      zIndex={2}
      size="xs"
      colorScheme="red"
      onClick={onRemove}
    />
    <Text position="absolute" bottom={0} left={0} fontSize="xs" bg="blackAlpha.600" color="white" px={1} borderRadius="md">
      {label}
    </Text>
  </Box>
));

const EliteImageUploadModal = ({ isOpen, onClose, isEdit = false, seteditData, editData, catName }) => {
  const [imageFiles, setImageFiles] = useState([]); // HD Wallpaper files
  const [thumbnailFiles, setThumbnailFiles] = useState([]); // Thumbnail files
  const [previewUrls, setPreviewUrls] = useState({ images: [], thumbnails: [] }); // Preview URLs for both
  const [progress, setProgress] = useState(0);
  const [subscriptionType, setSubscriptionType] = useState('Free')
  const [uploading, setUploading] = useState(false);
  const dispatch = useDispatch();
  const toast = useToast();

  // Create separate refs for each input
  const imageInputRef = useRef(null);
  const thumbnailInputRef = useRef(null);

  useEffect(() => {
    console.log('@updated subscriptionType state:', subscriptionType); // Logs when `subscriptionType` state changes
  }, [subscriptionType]);

  useEffect(() => {
    if (editData) {
      setSubscriptionType(editData.subscriptionType)
      setPreviewUrls({
        images: [process.env.REACT_APP_MEDIA_URL + editData.imagename], // HD image preview
        thumbnails: [process.env.REACT_APP_MEDIA_URL + editData.thumbnail], // Thumbnail preview
      });
    } else {
      setSubscriptionType('')
    }
  }, [editData]);

  const handleFileChange = (files, type) => {
    console.log(files, type);
    const newFiles = Array.from(files);
    const newUrls = newFiles.map((file) => URL.createObjectURL(file));

    if (type === "image") {
      setImageFiles(newFiles);
      setPreviewUrls((prev) => ({ ...prev, images: newUrls }));
    } else if (type === "thumbnail") {
      setThumbnailFiles(newFiles);
      setPreviewUrls((prev) => ({ ...prev, thumbnails: newUrls }));
    }
  };

  const handleUpload = async () => {
    setUploading(true);

    try {
      const formData = new FormData();
      formData.append('subscriptionType', subscriptionType)
      if (imageFiles.length > 0) {
        formData.append("eliteWallpaperImage", imageFiles[0]); // Append HD image
      }
      if (thumbnailFiles.length > 0) {
        formData.append("eliteWallpaperThumbnail", thumbnailFiles[0]); // Append thumbnail
      }

      let response;
      if (editData) {
        response = await dispatch(updateWallpaper(editData.id, formData));
      } else {
        formData.append("catName", catName);
        response = await dispatch(addWallpaper(formData));
      }

      toast({
        title: response.message || "Upload successful!",
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top-right", // Position of the toast
      });
      window.location.reload();
      // Reset the form after upload
      setSubscriptionType('')
      setImageFiles([]);
      setThumbnailFiles([]);
      setPreviewUrls({ images: [], thumbnails: [] });
      setProgress(0);
      seteditData(null);
      onClose();
    } catch (error) {
      console.error("Error uploading wallpapers:", error);
    } finally {
      setUploading(false);
    }
  };

  const handleModalClose = () => {
    [...previewUrls.images, ...previewUrls.thumbnails].forEach((url) => URL.revokeObjectURL(url));
    setImageFiles([]);
    setThumbnailFiles([]);
    setPreviewUrls({ images: [], thumbnails: [] });
    setProgress(0);
    onClose();
    seteditData(null);
  };

  const handleRemoveImage = (type, index) => {
    if (type === "image") {
      setImageFiles([]);
      setPreviewUrls((prev) => ({ ...prev, images: [] }));
    } else if (type === "thumbnail") {
      setThumbnailFiles([]);
      setPreviewUrls((prev) => ({ ...prev, thumbnails: [] }));
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={handleModalClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{editData ? "Update Wallpaper" : "Upload Wallpaper"}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>

           {/* SubscriptionType Field */}
           <Box mt={4}>
            <Text>Subscription Type:</Text>
            <Select value={subscriptionType} onChange={(e) => {setSubscriptionType(e.target.value);         
            }}>
              <option value="" disabled>Select a subscription type</option>
              <option value="Free">Free</option>
              <option value="Pro">Pro</option>
              <option value="Ads">Ads</option> 
              <option value="Seasonal">Seasonal</option> 
              <option value="Time">Time</option> 
              <option value="Flash">Flash</option> 
              <option value="Once">Once</option>
            </Select>
          </Box>
          {/*  */}
          <Text mb={2}>Upload Thumbnail:</Text>
          <Box p={4} borderWidth={2} borderColor={"gray.300"} borderStyle="dashed" borderRadius="md" textAlign="center">
            <Input ref={thumbnailInputRef} type="file" accept="image/*" onChange={(e) => handleFileChange(e.target.files, "thumbnail")} display="none" />
            <Button onClick={() => thumbnailInputRef.current.click()} colorScheme="blue">
              Select Thumbnail
            </Button>
          </Box>


          <Text mb={2}>Upload HD Image:</Text>
          <Box p={4} borderWidth={2} borderColor={"gray.300"} borderStyle="dashed" borderRadius="md" textAlign="center" mb={4}>
            <Input ref={imageInputRef} type="file" accept="image/*" onChange={(e) => handleFileChange(e.target.files, "image")} display="none" />
            <Button onClick={() => imageInputRef.current.click()} colorScheme="blue">
              Select HD Image
            </Button>
          </Box>          

          <Box mt={4}>
            <Flex gap={"4"} flexWrap={"wrap"}>
              {previewUrls.images.map((url, index) => (
                <ImageThumbnail key={index} url={url} onRemove={() => handleRemoveImage("image", index)} label="HD" />
              ))}
              {previewUrls.thumbnails.map((url, index) => (
                <ImageThumbnail key={index} url={url} onRemove={() => handleRemoveImage("thumbnail", index)} label="Thumbnail" />
              ))}
            </Flex>
          </Box>

          {uploading && (
            <Box mt={4}>
              <Text>Uploading... {progress.toFixed(0)}%</Text>
              <Progress value={progress} size="sm" colorScheme="green" />
            </Box>
          )}
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" onClick={handleUpload} isDisabled={ uploading}>
            {uploading ? "Uploading..." : "Upload"}
          </Button>
          <Button onClick={handleModalClose} ml={3}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default EliteImageUploadModal;
