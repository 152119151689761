import {
  Box,
  Flex,
  Heading,
  Input,
  Button,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Spinner,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormControl,
  FormLabel,
  useToast,
} from "@chakra-ui/react";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectCategories, selectError, selectLoading } from "../redux/eliteCategory/eliteCategorySelectors";
import { addCategory, deleteCategory, fetchCategories, updateCategory } from "../redux/eliteCategory/eliteCategoryActions";
import DeleteModal from "../components/DeleteModal";

function EliteCategory() {
  const [searchTerm, setSearchTerm] = useState("");
  const addCategoryModal = useDisclosure();
  const deleteCategoryModal = useDisclosure();
  const [newCategoryName, setNewCategoryName] = useState("");
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [categoryToDelete, setCategoryToDelete] = useState(null);

  const dispatch = useDispatch();
  const categories = useSelector(selectCategories);
  const loading = useSelector(selectLoading);
  const error = useSelector(selectError);
  const toast = useToast();

  useEffect(() => {
    dispatch(fetchCategories());
  }, [dispatch]);

  useEffect(() => {
    if (error) {
      toast({
        title: "Error fetching categories.",
        description: error,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }
  }, [toast, error]);

  const handleAddCategory = async () => {
    let formData = {
      cat_name: newCategoryName,
    };

    try {
      const response = selectedCategory ? await dispatch(updateCategory(selectedCategory?.id, formData)) : await dispatch(addCategory(formData));

      toast({
        title: "Category",
        description: response.message,
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    } catch (error) {
      // toast({
      //   title: "Error adding category.",
      //   description: error,
      //   status: "error",
      //   duration: 3000,
      //   isClosable: true,
      //   position: "top-right",
      // });
    } finally {
      setNewCategoryName("");
      addCategoryModal.onClose();
    }
  };

  const handleEditCategory = (category) => {
    setSelectedCategory(category);
    setNewCategoryName(category.cat_name);
    addCategoryModal.onOpen();
  };

  const openDeleteModal = (id) => {
    setCategoryToDelete(id);
    deleteCategoryModal.onOpen();
  };

  const handleDeleteCategory = async () => {
    try {
      const response = await dispatch(deleteCategory(categoryToDelete));
      toast({
        title: response.message, // Show success message from response
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    } catch (error) {
      toast({
        title: "Error deleting category.",
        description: error,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    } finally {
      deleteCategoryModal.onClose();
    }
  };

  const filteredCategories = categories?.filter((category) => category.cat_name.toLowerCase().includes(searchTerm.toLowerCase()));

  return (
    <Box p={5} bg="gray.100" minHeight="calc(100vh - 40px)">
      <Flex justify="space-between" align="center" mb={6}>
        <Heading as="h2" size="xl" color="teal.600">
          Elite Categories
        </Heading>
        <Button colorScheme="teal" variant="solid" _hover={{ bg: "teal.700" }} onClick={addCategoryModal.onOpen}>
          Add Category
        </Button>
      </Flex>
      <Input
        placeholder="Search categories..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        mb={4}
        borderColor="teal.300"
        _focus={{ borderColor: "teal.500", boxShadow: "outline" }}
      />
      {loading ? (
        <Flex justify={"center"} mt={4}>
          <Spinner size="lg" color="teal.500" />
        </Flex>
      ) : error ? (
        <Box color="red.500">{error}</Box>
      ) : categories.length === 0 ? (
        <Box>No categories found</Box>
      ) : (
        <Box overflowY="auto" maxH="calc(100vh - 200px)" borderWidth="1px" borderRadius="md" bg="white">
          <Table variant="simple" size="sm">
            <Thead>
              <Tr>
                <Th>ID</Th>
                <Th>Name</Th>
                <Th>Actions</Th>
              </Tr>
            </Thead>
            <Tbody>
              {filteredCategories?.map((category) => (
                <Tr key={category.id}>
                  <Td>{category.id}</Td>
                  <Td>{category.cat_name}</Td>
                  <Td>{new Date(category.createdAt).toLocaleDateString()}</Td>
                  <Td>
                    <Button size="sm" colorScheme="blue" onClick={() => handleEditCategory(category)}>
                      Edit
                    </Button>
                    <Button size="sm" colorScheme="red" onClick={() => openDeleteModal(category.id)} ml={2}>
                      Delete
                    </Button>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Box>
      )}

      {/* Modal for Adding Category */}
      <Modal
        isOpen={addCategoryModal.isOpen}
        onClose={() => {
          setNewCategoryName("");
          addCategoryModal.onClose();
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add New Category</ModalHeader>
          <ModalBody>
            <FormControl>
              <FormLabel>Category Name</FormLabel>
              <Input value={newCategoryName} onChange={(e) => setNewCategoryName(e.target.value)} placeholder="Enter category name" />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="teal" onClick={handleAddCategory} isDisabled={!newCategoryName} isLoading={loading}>
              {selectedCategory ? "Update" : "Add"} {/* Change button text */}{" "}
            </Button>
            <Button
              onClick={() => {
                setNewCategoryName("");
                addCategoryModal.onClose();
              }}
              ml={3}
            >
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Modal for Delete Confirmation */}
      <DeleteModal modal={deleteCategoryModal} onDelete={handleDeleteCategory} loading={loading} />
    </Box>
  );
}

export default EliteCategory;
