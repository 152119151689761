import { createBrowserRouter, Navigate, Outlet } from "react-router-dom";
import Dashboard from "../layouts/Dashboard";
import StaticCategory from "../pages/StaticCategory";
import StaticWallpaper from "../pages/StaticWallpaper";
import LiveCategory from "../pages/LiveCategory";
import LiveWallpaper from "../pages/LiveWallpaper";
import EliteCategory from "../pages/EliteCategory";
import EliteWallpaper from "../pages/EliteWallpaper";
import Product from "../pages/Product";
import Trend from "../pages/Trend";
import LoginPage from "../pages/LoginPage";
import DashboardPage from "../pages/DashboardPage";
import SettingsPage from "../pages/SettingPage";
import ArtisticWallpaper from "../pages/ArtisiticWallpaper";

const router = createBrowserRouter([
  {
    path: "/",
    element: <PublicRoutes />,
    children: [
      {
        path: "/",
        element: <LoginPage />,
      },
    ],
  },
  {
    path: "/dashboard",
    element: <PrivateRoutes />,
    children: [
      {
        path: "app", // Relative path
        element: <DashboardPage />,
      },
      {
        path: "static-categories", // Relative path
        element: <StaticCategory />,
      },
      {
        path: "static-wallpaper/:catName", // Relative path
        element: <StaticWallpaper />,
      },
      {
        path: "live-categories", // Relative path
        element: <LiveCategory />,
      },
      {
        path: "live-wallpaper/:catId", // Relative path
        element: <LiveWallpaper />,
      },
      {
        path: "elite-categories", // Relative path
        element: <EliteCategory />,
      },
      {
        path: "elite-wallpaper/:catName", // Relative path
        element: <EliteWallpaper />,
      },
      {
        path: "products", // Relative path
        element: <Product />,
      },
      {
        path: "settings", // Relative path
        element: <SettingsPage />,
      },
      {
        path: "trends", // Relative path
        element: <Trend />,
      },
      {
        path: "artistic-wallpaper", // Relative path
        element: <ArtisticWallpaper />,
      },
      {
        path: "*", // Relative path
        element: <Navigate to="/dashboard/app" replace />,
      },
    ],
  },
]);

function PublicRoutes() {
  const isLoggedIn = localStorage.getItem("isLoggedIn");

  if (isLoggedIn === "true") {
    return <Navigate to="/dashboard/app" replace />;
  }

  return <Outlet />;
}

function PrivateRoutes() {
  const isLoggedIn = localStorage.getItem("isLoggedIn");

  if (isLoggedIn !== "true") {
    return <Navigate to="/" replace />;
  }

  return <Dashboard />;
}

export default router;
