import {
  Box,
  Flex,
  Text,
  IconButton,
  Badge,
  Spinner,
  Center,
  useToast,
  Icon,
  Tooltip,
  Button,
  useDisclosure,
  SimpleGrid,
  Select,
  Switch,
} from "@chakra-ui/react";
import { EditIcon, DeleteIcon } from "@chakra-ui/icons";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { selectWallpapers } from "../redux/liveWallpaper/liveWallpaperSelector";
import {
  selectCategories,
  selectError,
  selectLoading,
} from "../redux/liveCategory/liveCategorySelectors";
import { fetchWallpapers } from "../redux/liveWallpaper/liveWallpaperActions";
import { useEffect, useState, useRef } from "react";
import { FaDownload, FaEye, FaHeart } from "react-icons/fa";
import axiosInstance from "../api/axiosInstance";
import AlertDialogComp from "../components/AlertDialogComp";
import { FixedSizeList as List } from "react-window";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import LiveImageUploadModal from "../components/LiveImageUploadModal";
import { fetchCategories } from "../redux/liveCategory/liveCategoryActions";

const WallpaperItem = ({ wallpaper, onEdit, onDelete }) => (
  <Box
    borderWidth="1px"
    borderRadius="lg"
    overflow="hidden"
    boxShadow="md"
    position="relative"
  >
    <Box position="relative">
      <LazyLoadImage
        src={
          process.env.REACT_APP_MEDIA_URL +
          "/liveWallpapers/compress/" +
          wallpaper.thumbnail
        }
        alt={wallpaper.thumbnail}
        effect="blur"
        width="100%"
        style={{
          maxHeight: "200px",
          height: "100%",
          objectFit: "cover",
          minHeight: "200px",
        }}
      />
      <Box
        position="absolute"
        bottom={2}
        left={2}
        bg="rgba(0, 0, 0, 0.6)"
        color="white"
        px={2}
        py={1}
        borderRadius="md"
        fontSize="sm"
      >
        {wallpaper.dimension}
      </Box>
      <Box
        position="absolute"
        bottom={2}
        right={2}
        bg="rgba(0, 0, 0, 0.6)"
        color="white"
        px={2}
        py={1}
        borderRadius="md"
        fontSize="sm"
      >
        {wallpaper.size}
      </Box>
    </Box>

    <Flex position="absolute" top={2} right={2}>
      <IconButton
        aria-label="Edit Wallpaper"
        icon={<EditIcon />}
        colorScheme="teal"
        size="sm"
        mr={2}
        onClick={() => onEdit(wallpaper)}
      />
      <IconButton
        aria-label="Delete Wallpaper"
        icon={<DeleteIcon />}
        colorScheme="red"
        size="sm"
        onClick={() => onDelete(wallpaper.id)}
      />
    </Flex>

    <Box p={4}>
      <Flex justifyContent="space-between" alignItems="center" mb={2}>
        <Box display="flex" gap={2} flexWrap="wrap">
          <Badge colorScheme="blue">{wallpaper?.category}</Badge>
          <Badge colorScheme="purple">{wallpaper?.quality}</Badge>
          <Badge
            colorScheme={getSubscriptionBadgeColor(wallpaper?.subscriptionType)}
          >
            {wallpaper?.subscriptionType}
          </Badge>
          <Badge colorScheme="red">{wallpaper?.status}</Badge>
        </Box>
        <Text fontSize="sm" color="gray.500" ml="auto">
          {new Date(wallpaper.createdAt).toLocaleDateString()}
        </Text>
      </Flex>

      <Text fontWeight="bold" fontSize="md" mb={2}>
        <Tooltip label={wallpaper.new_name} fontSize="md">
          <span>{wallpaper.new_name}</span>
        </Tooltip>
      </Text>

      <Flex justifyContent="space-between" alignItems="center">
        <Flex alignItems="center" mr={4}>
          <Icon as={FaHeart} color="red.500" mr={1} />
          <Text fontSize="sm">{wallpaper.likes}</Text>
        </Flex>
        <Flex alignItems="center" mr={4}>
          <Icon as={FaEye} color="gray.500" mr={1} />
          <Text fontSize="sm">{wallpaper.views}</Text>
        </Flex>
        <Flex alignItems="center">
          <Icon as={FaDownload} color="blue.500" mr={1} />
          <Text fontSize="sm">{wallpaper.downloads}</Text>
        </Flex>
      </Flex>
    </Box>
  </Box>
);

const getSubscriptionBadgeColor = (type) => {
  switch (type) {
    case "Free":
      return "blue";
    case "Pro":
      return "purple";
    case "Ads":
      return "orange";
    case "Seasonal":
      return "yellow";
    case "Time":
      return "teal";
    case "Flash":
      return "pink";
    case "Once":
      return "cyan";
    default:
      return "gray"; // Default color if type is unknown
  }
};


const LiveWallpaper = () => {
  const { catId } = useParams();
  const dispatch = useDispatch();
  const wallpapers = useSelector(selectWallpapers);
  const loading = useSelector(selectLoading);
  const error = useSelector(selectError);
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [editData, seteditData] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);

  const {
    isOpen: isDeleteOpen,
    onOpen: onDeleteOpen,
    onClose: onDeleteClose,
  } = useDisclosure();
  const cancelRef = useRef();
  const [deleteData, setDeleteData] = useState(null);

  const [sortField, setSortField] = useState(""); // State to track sort field
  const [sortOrder, setSortOrder] = useState("asc"); // State to track sort order

  const ITEM_HEIGHT = 380; // Set item height
  const COLUMN_COUNT = 5; // Number of columns
  const ROW_HEIGHT = ITEM_HEIGHT; // Total height of each row
  const visibleItemsCount = Math.ceil(wallpapers.length / COLUMN_COUNT);
  const categories = useSelector(selectCategories);

  const handleSortChange = (e) => {
    setSortField(e.target.value);
  };

  const toggleSortOrder = () => {
    setSortOrder((prev) => (prev === "asc" ? "desc" : "asc"));
  };

  const sortWallpapers = (wallpapers) => {
    if (!sortField) return wallpapers;
    return [...wallpapers].sort((a, b) => {
      if (sortOrder === "asc") {
        return a[sortField] > b[sortField] ? 1 : -1;
      } else {
        return a[sortField] < b[sortField] ? 1 : -1;
      }
    });
  };

  useEffect(() => {
    dispatch(fetchWallpapers(catId));
    dispatch(fetchCategories());
  }, [dispatch, catId]);

  useEffect(() => {
    if (error) {
      toast({
        title: "Error fetching wallpapers.",
        description: error,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }
  }, [toast, error]);

  const handleEditWallpaper = (id) => {
    seteditData(id);
    onOpen();
  };

  const handleDeleteWallpaper = (id) => {
    setDeleteData(id);
    onDeleteOpen();
  };

  const confirmDeleteWallpaper = async () => {
    setIsDeleting(true);
    try {
      await axiosInstance.delete(`/live-wallpaper/${deleteData}`);
      toast({
        title: "Wallpaper deleted.",
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      dispatch(fetchWallpapers(catId));
      onDeleteClose();
    } catch (error) {
      toast({
        title: "Error deleting wallpaper.",
        description: error.response?.data?.message || "An error occurred.",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    } finally {
      setIsDeleting(false);
    }
  };

  const handleModalClose = () => {
    seteditData(null);
    onClose();
  };

  const sortedWallpapers = sortWallpapers(wallpapers);

  if (loading) {
    return (
      <Center height="100vh">
        <Spinner size="xl" color="teal.500" />
      </Center>
    );
  }

  return (
    <Box p={5}>
      <Flex justify="space-between" alignItems="center" mb={2}>
        <Text fontSize="xl" fontWeight="bold">
          {sortedWallpapers[0]?.category || catId}{" "}
          {"(" + sortedWallpapers?.length + ")"}
        </Text>
        <Button
          variant="solid"
          colorScheme="teal"
          _hover={{ bg: "teal.700" }}
          onClick={onOpen}
        >
          Add Live Wallpaper
        </Button>
      </Flex>

      <Flex mb={4} justify="space-between" alignItems="center">
        <Select
          placeholder="Sort by"
          value={sortField}
          onChange={handleSortChange}
          width="200px"
        >
          <option value="likes">Likes</option>
          <option value="views">Views</option>
          <option value="downloads">Downloads</option>
        </Select>
        <Flex alignItems="center">
          <Text mr={2}>Ascending</Text>
          <Switch
            colorScheme="teal"
            isChecked={sortOrder === "desc"} // Checked if descending
            onChange={toggleSortOrder} // Switch toggles the order
          />
          <Text ml={2}>Descending</Text>
        </Flex>
      </Flex>

      <Box>
        {sortedWallpapers.length === 0 ? (
          <Center height="50vh">
            <Text fontSize="xl" color="gray.500">
              No wallpapers available.
            </Text>
          </Center>
        ) : (
          <List
            height={window.innerHeight - 100}
            style={{ maxHeight: "calc(100vh - 130px)" }}
            itemCount={visibleItemsCount}
            itemSize={ROW_HEIGHT}
            width="100%"
          >
            {({ index, style }) => {
              const startIndex = index * COLUMN_COUNT;
              const endIndex = Math.min(
                startIndex + COLUMN_COUNT,
                sortedWallpapers.length
              );
              return (
                <SimpleGrid
                  columns={COLUMN_COUNT}
                  spacing={5}
                  p={5}
                  style={style}
                >
                  {sortedWallpapers
                    .slice(startIndex, endIndex)
                    .map((wallpaper, i) => (
                      <WallpaperItem
                        key={wallpaper.id}
                        wallpaper={wallpaper}
                        onEdit={handleEditWallpaper}
                        onDelete={handleDeleteWallpaper}
                      />
                    ))}
                </SimpleGrid>
              );
            }}
          </List>
        )}
      </Box>

      <LiveImageUploadModal
        isOpen={isOpen}
        onClose={handleModalClose}
        editData={editData}
        seteditData={seteditData}
        catName={catId}
        categories={categories}
      />
      <AlertDialogComp
        isOpen={isDeleteOpen}
        onClose={onDeleteClose}
        cancelRef={cancelRef}
        isLoading={isDeleting}
        title="Delete Wallpaper"
        body="Are you sure you want to delete this wallpaper?"
        confirm={confirmDeleteWallpaper}
      />
    </Box>
  );
};

export default LiveWallpaper;
