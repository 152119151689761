import { createSlice } from '@reduxjs/toolkit';

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState: {
    counts: null,
    loading: false,
    error: null,
  },
  reducers: {
    fetchDashboardRequest(state) {
      state.loading = true;
    },
    fetchDashboardSuccess(state, action) {
      state.loading = false;
      state.counts = action.payload;
    },
    fetchDashboardFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  fetchDashboardRequest,
  fetchDashboardSuccess,
  fetchDashboardFailure,
} = dashboardSlice.actions;

export default dashboardSlice.reducer;
