import { Box, Flex, Heading, Stack, Text, Icon, Collapse, Image, useBreakpointValue, IconButton } from "@chakra-ui/react";
import { MdDashboard, MdAssessment, MdDescription, MdSettings, MdExpandMore, MdExpandLess, MdMenu } from "react-icons/md";
import { useColorMode } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { Link, Outlet } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { selectCategories } from "../redux/staticCategory/staticCategorySelectors";
import { selectCategories as selectedLiveCategories } from "../redux/liveCategory/liveCategorySelectors";
import { selectCategories as selectedEliteCategories } from "../redux/eliteCategory/eliteCategorySelectors";
import { fetchCategories } from "../redux/staticCategory/staticCategoryActions";
import { fetchCategories as fetchLiveCategories } from "../redux/liveCategory/liveCategoryActions";
import { fetchCategories as fetchEliteCategories } from "../redux/eliteCategory/eliteCategoryActions";

function Dashboard() {
  const { colorMode } = useColorMode();
  const [isStaticOpen, setIsStaticOpen] = useState(false);
  const [isLiveOpen, setIsLiveOpen] = useState(false);
  const [isEliteOpen, setIsEliteOpen] = useState(false);
  const [isSidebarVisible, setIsSidebarVisible] = useState(true);
  const dispatch = useDispatch();
  const categories = useSelector(selectCategories);
  const liveCategories = useSelector(selectedLiveCategories);
  const eliteCategories = useSelector(selectedEliteCategories);

  useEffect(() => {
    dispatch(fetchCategories());
    dispatch(fetchLiveCategories());
    dispatch(fetchEliteCategories());
  }, [dispatch]);

  const toggleStaticDropdown = () => setIsStaticOpen(!isStaticOpen);
  const toggleLiveDropdown = () => setIsLiveOpen(!isLiveOpen);
  const toggleEliteDropdown = () => setIsEliteOpen(!isEliteOpen);
  const toggleSidebar = () => setIsSidebarVisible(!isSidebarVisible);

  const sidebarWidth = useBreakpointValue({ base: "250px", md: "250px" });

  return (
    <Flex minHeight="100vh" position="relative">
      {/* Sidebar */}
      <Box
        width={sidebarWidth}
        position="absolute" // Set sidebar position to absolute
        left={0}
        top={0}
        bottom={0}
        zIndex={999}
        overflow={"auto"}
        bg={colorMode === "dark" ? "gray.800" : "#f1f1f1"}
        color={colorMode === "dark" ? "white" : "gray.700"}
        p={5}
        transition="width 0.2s ease" // Smooth transition
        display={{ base: isSidebarVisible ? "block" : "none", md: "block" }} // Control visibility
      >
        <Flex align="center" justify="space-between">
          <Heading as="h1" size="lg" mb={6}>
            OHMYWALL
          </Heading>
        </Flex>
        <Stack spacing={4}>
          <SidebarItem icon={MdDashboard} text="Dashboard" link="/dashboard/app" />
          <SidebarItem icon={MdAssessment} text="Static Categories" link="/dashboard/static-categories" />
          <Flex align="center" onClick={toggleStaticDropdown} cursor="pointer" width="100%" justify="space-between" pl={2} pr={2}>
            <Flex align="center" gap="3" flex="1">
              <Icon as={MdDescription} boxSize={5} />
              <Text>Static Wallpaper</Text>
            </Flex>
            <Icon as={isStaticOpen ? MdExpandLess : MdExpandMore} boxSize={5} />
          </Flex>
          <Collapse in={isStaticOpen}>
            <Stack spacing={1} pl={8}>
              <Box height={"100%"} maxHeight={"270px"} overflow={"auto"}>
                {categories?.map((item) => (
                  <SidebarItem
                    key={item.id}
                    text={item.catName}
                    link={"/dashboard/static-wallpaper/" + item.catName}
                    image={process.env.REACT_APP_MEDIA_URL + "/" + item.imageName}
                  />
                ))}
              </Box>
            </Stack>
          </Collapse>
          <SidebarItem icon={MdAssessment} text="Live Categories" link="/dashboard/live-categories" />
          <Flex align="center" onClick={toggleLiveDropdown} cursor="pointer" width="100%" justify="space-between" pl={2} pr={2}>
            <Flex align="center" gap="3" flex="1">
              <Icon as={MdDescription} boxSize={5} />
              <Text>Live Wallpaper</Text>
            </Flex>
            <Icon as={isLiveOpen ? MdExpandLess : MdExpandMore} boxSize={5} />
          </Flex>
          <Collapse in={isLiveOpen}>
            <Stack spacing={1} pl={8}>
              <Box height={"100%"} maxHeight={"270px"} overflow={"auto"}>
                {liveCategories?.map((item) => (
                  <SidebarItem
                    key={item.id}
                    text={item.catName}
                    link={"/dashboard/live-wallpaper/" + item.id}
                    image={process.env.REACT_APP_MEDIA_URL + "/" + item.imageName}
                  />
                ))}
              </Box>
            </Stack>
          </Collapse>
          <SidebarItem icon={MdAssessment} text="Elite Categories" link="/dashboard/elite-categories" />
          <Flex align="center" onClick={toggleEliteDropdown} cursor="pointer" width="100%" justify="space-between" pl={2} pr={2}>
            <Flex align="center" gap="3" flex="1">
              <Icon as={MdDescription} boxSize={5} />
              <Text>Elite Wallpaper</Text>
            </Flex>
            <Icon as={isEliteOpen ? MdExpandLess : MdExpandMore} boxSize={5} />
          </Flex>
          <Collapse in={isEliteOpen}>
            <Stack spacing={1} pl={8}>
              <Box height={"100%"} maxHeight={"270px"} overflow={"auto"}>
                {eliteCategories?.map((item) => (
                  <SidebarItem key={item.id} text={item.cat_name} link={"/dashboard/elite-wallpaper/" + item.cat_name} />
                ))}
              </Box>
            </Stack>
          </Collapse>
          <SidebarItem icon={MdAssessment} text="Artistic Wallpaper" link="/dashboard/artistic-wallpaper" />

          <SidebarItem icon={MdAssessment} text="Products" link="/dashboard/products" />
          <SidebarItem icon={MdAssessment} text="Trends" link="/dashboard/trends" />
          <SidebarItem icon={MdSettings} text="Settings" link="/dashboard/settings" />
        </Stack>
      </Box>

      {/* Main Content Area */}
      <Box
        flex="1"
        p={5}
        ml={{ base: "0", md: sidebarWidth }}
        bg={colorMode === "dark" ? "gray.900" : "gray.50"}
        transition="margin-left 0.2s ease"
        onClick={toggleSidebar}
      >
        {/* Sidebar Toggle Button for Mobile */}
        <IconButton aria-label="Toggle Sidebar" icon={<MdMenu />} onClick={toggleSidebar} display={{ base: "flex", md: "none" }} m={2} />

        <Outlet />
      </Box>
    </Flex>
  );
}

// Sidebar item component
const SidebarItem = ({ icon, text, link, image }) => {
  return (
    <Link to={link}>
      <Flex align="center" p={2} borderRadius="md" _hover={{ bg: "teal.500", cursor: "pointer", color: "white" }}>
        {icon && <Icon as={icon} boxSize={5} mr={3} />}
        {image && <Image src={image} width={30} height={30} mr={2} rounded={"full"} />}
        <Text>{text}</Text>
      </Flex>
    </Link>
  );
};

export default Dashboard;
