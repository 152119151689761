import {
  Box,
  Flex,
  Heading,
  Input,
  Button,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Spinner,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormControl,
  FormLabel,
  useToast,
} from "@chakra-ui/react";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectProducts, selectError, selectLoading } from "../redux/product/productSelectors";
import { fetchProducts, addProduct, updateProduct, deleteProduct } from "../redux/product/productActions";
import DeleteModal from "../components/DeleteModal";

function Product() {
  const [searchTerm, setSearchTerm] = useState("");
  const addProductModal = useDisclosure();
  const deleteProductModal = useDisclosure();

  // New state fields for form inputs
  const [device_id, setDeviceId] = useState("");
  const [product_id, setProductId] = useState("");
  const [transaction_id, setTransactionId] = useState("");
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [productToDelete, setProductToDelete] = useState(null);

  const dispatch = useDispatch();
  const products = useSelector(selectProducts);
  const loading = useSelector(selectLoading);
  const error = useSelector(selectError);
  const toast = useToast();

  useEffect(() => {
    dispatch(fetchProducts());
  }, [dispatch]);

  useEffect(() => {
    if (error) {
      toast({
        title: "Product Error",
        description: error,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }
  }, [toast, error]);

  const handleAddProduct = async () => {
    const formData = {
      device_id,
      product_id,
      transaction_id,
    };

    try {
      const response = selectedProduct ? await dispatch(updateProduct(selectedProduct?.id, formData)) : await dispatch(addProduct(formData));

      toast({
        title: "Product",
        description: response.message,
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    } catch (error) {
      toast({
        title: "Error adding product.",
        description: error,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    } finally {
      setDeviceId("");
      setProductId("");
      setTransactionId("");
      addProductModal.onClose();
    }
  };

  const handleEditProduct = (product) => {
    setSelectedProduct(product);
    setDeviceId(product.device_id);
    setProductId(product.product_id);
    setTransactionId(product.transaction_id);
    addProductModal.onOpen();
  };

  const openDeleteModal = (id) => {
    setProductToDelete(id);
    deleteProductModal.onOpen();
  };

  const handleDeleteProduct = async () => {
    try {
      const response = await dispatch(deleteProduct(productToDelete));
      toast({
        title: response.message,
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    } catch (error) {
      // toast({
      //   title: "Error deleting product.",
      //   description: error,
      //   status: "error",
      //   duration: 3000,
      //   isClosable: true,
      //   position: "top-right",
      // });
    } finally {
      deleteProductModal.onClose();
    }
  };

  const filteredProducts = products?.filter(
    (product) =>
      product.product_id.toLowerCase().includes(searchTerm.toLowerCase()) ||
      product.device_id.toLowerCase().includes(searchTerm.toLowerCase()) ||
      product.transaction_id.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <Box p={5} bg="gray.100" minHeight="calc(100vh - 40px)">
      <Flex justify="space-between" align="center" mb={6}>
        <Heading as="h2" size="xl" color="teal.600">
          Product Management
        </Heading>
        <Button colorScheme="teal" variant="solid" _hover={{ bg: "teal.700" }} onClick={addProductModal.onOpen}>
          Add Product
        </Button>
      </Flex>

      <Input
        placeholder="Search products..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        mb={4}
        borderColor="teal.300"
        _focus={{ borderColor: "teal.500", boxShadow: "outline" }}
      />

      {loading ? (
        <Flex justify={"center"} mt={4}>
          <Spinner size="lg" color="teal.500" />
        </Flex>
      ) : error ? (
        <Box color="red.500">{error}</Box>
      ) : products.length === 0 ? (
        <Box>No products found</Box>
      ) : (
        <Box overflowY="auto" maxH="calc(100vh - 200px)" borderWidth="1px" borderRadius="md" bg="white">
          <Table variant="simple" size="sm">
            <Thead>
              <Tr>
                <Th>Device ID</Th>
                <Th>Product ID</Th>
                <Th>Transaction ID</Th>
                <Th>Actions</Th>
              </Tr>
            </Thead>
            <Tbody>
              {filteredProducts?.map((product) => (
                <Tr key={product.id}>
                  <Td>{product.device_id}</Td>
                  <Td>{product.product_id}</Td>
                  <Td>{product.transaction_id}</Td>
                  <Td>
                    <Button size="sm" colorScheme="blue" onClick={() => handleEditProduct(product)}>
                      Edit
                    </Button>
                    <Button size="sm" colorScheme="red" onClick={() => openDeleteModal(product.id)} ml={2}>
                      Delete
                    </Button>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Box>
      )}

      {/* Modal for Adding Product */}
      <Modal
        isOpen={addProductModal.isOpen}
        onClose={() => {
          setDeviceId("");
          setProductId("");
          setTransactionId("");
          addProductModal.onClose();
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{selectedProduct ? "Edit Product" : "Add New Product"}</ModalHeader>
          <ModalBody>
            <FormControl>
              <FormLabel>Device ID</FormLabel>
              <Input value={device_id} onChange={(e) => setDeviceId(e.target.value)} placeholder="Enter device ID" />
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>Product ID</FormLabel>
              <Input value={product_id} onChange={(e) => setProductId(e.target.value)} placeholder="Enter product ID" />
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>Transaction ID</FormLabel>
              <Input value={transaction_id} onChange={(e) => setTransactionId(e.target.value)} placeholder="Enter transaction ID" />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="teal" onClick={handleAddProduct} isDisabled={!device_id || !product_id || !transaction_id} isLoading={loading}>
              {selectedProduct ? "Update" : "Add"}
            </Button>
            <Button
              onClick={() => {
                setDeviceId("");
                setProductId("");
                setTransactionId("");
                addProductModal.onClose();
              }}
              ml={3}
            >
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Modal for Delete Confirmation */}
      <DeleteModal modal={deleteProductModal} onDelete={handleDeleteProduct} loading={loading} />
    </Box>
  );
}

export default Product;
