import axiosInstance from "../../api/axiosInstance";
import { fetchTrendRequest, fetchTrendSuccess, fetchTrendFailure } from "./trendSlice";

// Action to fetch trends
export const fetchTrends = () => async (dispatch) => {
  dispatch(fetchTrendRequest());
  try {
    const response = await axiosInstance.get("/trends");
    dispatch(fetchTrendSuccess(response.data));
    return response.data; // Return response data
  } catch (error) {
    dispatch(fetchTrendFailure(error.message));
  }
};

// Action to add a new trend
export const addTrend = (formData) => async (dispatch, getState) => {
  dispatch(fetchTrendRequest());
  try {
    const response = await axiosInstance.post("/trends", formData);
    if (response) {
      const trend = response.data.trend;
      const { trends } = getState().trends;
      dispatch(fetchTrendSuccess([trend, ...trends]));
    }
    return response.data;
  } catch (error) {
    if (error && error.response) {
      dispatch(fetchTrendFailure(error.response.data.message));
    } else {
      dispatch(fetchTrendFailure(error.message));
    }
    return error;
  }
};

// Action to update trend
export const updateTrend = (id, formData) => async (dispatch, getState) => {
  dispatch(fetchTrendRequest());
  try {
    const response = await axiosInstance.put(`/trends/${id}`, formData);
    const updatedTrend= response.data.trend;
    const { trends } = getState().trends;

    const updatedTrends = trends.map((trend) => (trend.id === updatedTrend.id ? updatedTrend : trend));

    dispatch(fetchTrendSuccess(updatedTrends));

    return response.data;
  } catch (error) {
    dispatch(fetchTrendFailure(error.message));
    return error;
  }
};

// Action to delete a trend
export const deleteTrend = (trendId) => async (dispatch, getState) => {
  dispatch(fetchTrendRequest());
  try {
    const response = await axiosInstance.delete("/trends/" + trendId);
    const { trends } = getState().trends;
    const updatedTrends = trends.filter((trend) => trend.id !== trendId);
    dispatch(fetchTrendSuccess(updatedTrends));
    return response.data;
  } catch (error) {
    if (error.response) {
      dispatch(fetchTrendFailure(error.response.data.message));
    } else {
      dispatch(fetchTrendFailure(error.message));
    }
  }
};
