import {
    Box,
    Button,
    Card,
    CardBody,
    CardHeader,
    FormControl,
    FormLabel,
    Input,
    VStack,
    Heading,
    Text,
    useToast,
  } from '@chakra-ui/react';
  import { useState } from 'react';
  import { useNavigate } from 'react-router-dom';
  
  const LoginPage = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();
    const toast = useToast();
  
    const handleLogin = () => {
      const storedUsername = process.env.REACT_APP_USERNAME;
      // const storedEmail = process.env.REACT_APP_EMAIL;
      const storedPassword = process.env.REACT_APP_PASSWORD;

      console.log(username)
      console.log(password)
      console.log(storedUsername)
      console.log(storedPassword)
  
      if (username === storedUsername && password === storedPassword) {
        localStorage.setItem('isLoggedIn', 'true');
        navigate('/dashboard/app');
      } else {
        toast({
          title: 'Invalid credentials',
          description: 'Please check your username and password.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    };
  
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
        bg="gray.50"
        p={4}
      >
        <Card maxW="md" w="100%" boxShadow="lg" borderRadius="lg" p={6} bg="white">
          <CardHeader textAlign="center">
            <VStack spacing={4}>
             
              <Heading size="lg">Welcome Back</Heading>
              <Text fontSize="md" color="gray.500">
                Please enter your login credentials.
              </Text>
            </VStack>
          </CardHeader>
          <CardBody>
            <FormControl mb={4}>
              <FormLabel>Username</FormLabel>
              <Input
                type="text"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                placeholder="Enter your username"
                focusBorderColor="blue.500"
                bg="gray.100"
              />
            </FormControl>
            <FormControl mb={6}>
              <FormLabel>Password</FormLabel>
              <Input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Enter your password"
                focusBorderColor="blue.500"
                bg="gray.100"
              />
            </FormControl>
            <Button
              colorScheme="blue"
              onClick={handleLogin}
              width="100%"
              py={6}
              fontSize="md"
            >
              Login
            </Button>
          </CardBody>
        </Card>
      </Box>
    );
  };
  
  export default LoginPage;
  