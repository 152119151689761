import { createSlice } from '@reduxjs/toolkit';

const trendSlice = createSlice({
  name: 'trends',
  initialState: {
    trends: [],
    loading: false,
    error: null,
  },
  reducers: {
    fetchTrendRequest(state) {
      state.loading = true;
    },
    fetchTrendSuccess(state, action) {
      state.loading = false;
      state.trends = action.payload;
    },
    fetchTrendFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  fetchTrendRequest,
  fetchTrendSuccess,
  fetchTrendFailure,
} = trendSlice.actions;

export default trendSlice.reducer;
