export const formatFileSize = (kb) => {
  // Ensure kb is a string, and remove commas
  const cleanKb = kb.toString().replace(/,/g, '');

  // Convert to number and check if it's valid
  const numericKb = Number(cleanKb);
  if (isNaN(numericKb)) return "Invalid size"; // Handle NaN case

  if (numericKb === 0) return "0 KB"; // For zero KB

  const mbThreshold = 1024; // 1 MB in KB

  if (numericKb < mbThreshold) {
    return numericKb.toFixed(2) + " KB"; // Display in KB if less than 1 MB
  } else {
    return (numericKb / mbThreshold).toFixed(2) + " MB"; // Display in MB
  }
};


export const addFileSize = (currentSizeKB, sizeToAdd, unit) => {
  let sizeInKB;

  // Convert sizeToAdd to kilobytes based on the unit
  switch (unit) {
    case "KB":
      sizeInKB = sizeToAdd; // Already in KB
      break;
    case "MB":
      sizeInKB = sizeToAdd * 1024; // Convert MB to KB
      break;
    case "GB":
      sizeInKB = sizeToAdd * Math.pow(1024, 2); // Convert GB to KB
      break;
    default:
      throw new Error("Invalid unit provided. Use 'KB', 'MB', or 'GB'.");
  }

  // Add the converted size to the current size in KB
  return formatFileSize(currentSizeKB + sizeInKB);
};

export const handleError = (error) => {
  let errorMessage = "";

  if (error.response && error.response.data && error.response.data.errors && Array.isArray(error.response.data.errors)) {
    errorMessage = error.response.data.errors.map((err) => `${err.msg}`);
  } else if (error.response && error.response.data && (error.response.data.message || error.response.data.error)) {
    errorMessage = error.response.data.message || error.response.data.error;
  } else {
    errorMessage = "An unexpected error occurred. Please try again later.";
  }

  return errorMessage;
};
export const truncateAndRemoveExtension = (name, maxLength) => {
  if (!name || !maxLength) return;
  const nameWithoutExtension = name.split(".").slice(0, -1).join(".");

  if (nameWithoutExtension.length > maxLength) {
    return nameWithoutExtension.substring(0, maxLength) + "...";
  }

  return nameWithoutExtension;
};

export const convertSize = (size) => {
  let sizeData;
  if (size < 1024) {
    sizeData = `${size} Bytes`;
  } else if (size < 1024 * 1024) {
    sizeData = `${(size / 1024).toFixed(2)} KB`;
  } else {
    sizeData = `${(size / (1024 * 1024)).toFixed(2)} MB`;
  }

  return sizeData;
};
